// 
// Page-title
// 

.page-title-box {
    // padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {        
        font-weight: 600;
        font-size: 20px !important;
        line-height: 24px;
        color: $heading-main;
        font-family: 'Montserrat', sans-serif;
    }
}