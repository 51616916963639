.order-number{
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  color: #20253a;
}
.order-date{
  font-size: 12px;
  font-weight: 500;
  color: #6c6c6c;
}
.card-table{
  padding: 24px 29px;
}
.paid-card{
  padding: 24px 29px;
}
.paid{
  font-size: 14px;
font-weight: 600;
color: #3d3839;
margin-bottom: 14px;
img{
    width: 27px;
    margin-right: 10px;
}
}
.paid-main-div{
  display: flex;
  justify-content: space-between;
}
.paid-bill-div{
  display: flex;
  justify-content: space-between;
  width: 40%;
}
.paid-subtotal{
  display: flex;
  flex-direction: column;
}
.paid-total{
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 500;
  line-height: 2.15;
  color: #495057;
}
.subtotal {
  font-size: 13px;
  font-weight: 600;
  line-height: 2.15;
  color: #495057;
}
.total-text{
  font-size: 13px;
  font-weight: 600;
  line-height: 2.15;
  color: #495057;
}
.card__table__header{
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 3fr 2fr 1.5fr 1.5fr 1.5fr;
  font-size: 13px;
  font-weight: bold;
  line-height: 0.77;
  color: #495057;
}
.unfulfilled-text{
  margin-bottom: 24px;
}
.card__table__body{
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 3fr 2fr 1.5fr 1.5fr 1.5fr;
  font-size: 13px;
font-weight: 500;
color: #495057;
}
.paid-items{
  font-size: 13px;
font-weight: 500;
line-height: 2.15;
color: #495057;
}
.comman-heading{
  font-size: 14px;
  font-weight: bold;
  color: #495057;
}
.comman-text{
  font-size: 13px;
  line-height: 1.71;
  color: #495057;
}
.comman-text-bold{
  font-weight: 600
}
.table-p{
  // display: flex;
  // justify-content: center;
  // border-right: solid 1px #ebebeb;
}
@media only screen and (max-width: 580px) {
.select-order-status{
  flex-direction: column;
  row-gap: 10px;
}
.details-container{
  overflow-x: scroll;
}
}
@media only screen and (max-width: 480px) {
.order-dropdown{
  flex-direction: column;
}
.shipment-drop{
  margin-left: 0 !important;
}
}
.mobile-qty{
display: none;
}
.my-div{
  display: flex;
  justify-content: center;
  border-right: 1px solid;
  align-items: center;
  height: 40px;
}
.select-order-status{
display: flex;
justify-content: space-around;
align-items: center;
margin-top: 30px;
margin-bottom: 30px;
padding: 0 15px;
div{
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.center-portion{
    &::before{
      content: "";
      height: 80px;
      position: absolute;
      width: 2px;
      background: #f1f1f2;
      @media only screen and (max-width: 580px) {
        background: transparent;
      }
    }
    span{
      background: #f1f1f2;
      font-size: 12px;
      font-weight: bold;
      padding: 5px;
      letter-spacing: 1px;
      border-radius: 15px;
    }
  }
  .description{
    text-align: center;
    margin-top: 5px;
  }
}
&.on-modal{
  margin-top: 12px;
  margin-bottom: 0px;
  padding-left: 5px;
  padding-right: 0px;
  justify-content: space-between;
  div{
    flex-direction: row;
    max-width: initial;
  }
}
}
.order-detils-div{
font-size: 14px;
font-weight: 600;
color: #3d3839;
margin-right: 10px;
}
.order-dropdown{
display: flex;
align-items: center;
}
.shipment-btn{
padding: 8px 15px;
color: #fff;
font-size: 14px;
font-weight: 500;
background-color: #0069a7;
border: none;
}
.shipment-drop{
width: 214px;
height: 35px;
border-radius: 3px;
border: solid 1px #c4c4c4;
background-color: var(--white);
margin-left: 5px;
}
@media only screen and (max-width: 480px) {
.paid-bill-div {
  width: 73%;
}
}
input[type=checkbox] {
  cursor: pointer;
}
.products__table__container{
  height: auto;
  overflow-y: scroll;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.02);
  &::-webkit-scrollbar {
    display: none;
}
}
.products__table__container__un-scrollable{
  height: auto;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.02);
}
.products__table__header {
  padding: 20px 1vh;
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 3fr 2fr 1.5fr 1.5fr 1.5fr;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #645a5b;
  border-bottom: solid 1px #ebebeb;
  text-align: center;
  background-color: #fff;
  border: solid 1px rgba(227, 227, 227, 0.57);
  p {
    margin: 0;
  }
}
.quantity__input__container {
  width: 89px !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.products__upper__description {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.92px;
  color: #645a5b;
}

.products__part__no {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #0069a7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.products__price__each {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #898383;
}

.item-3{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #0069a7;
}

.products__plus,
.products__minus {
  position: absolute;
  font-size: 11px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &:hover{
    opacity: 0.6;
  }
}
.products__minus {
  left: 5px;
}
.products__plus {
  right: 5px;
}
.item-1 {
  height: 18px;
  width: 18px;
}
.item-2,
.item-3,
.item-4,
.item-5,
.item-7 {
  border-right: 1px solid #ebebeb;
  height: -webkit-fill-available;
}
.item-4,
.item-5 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-7 p {
  justify-content: center;
  height: 100%;
}
.item-6 {
  display: flex;
  justify-content: center;
  border-right: 1px solid #ebebeb;
  height: 100%;
  align-items: center;
}
.product__total__text{
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  // text-align: right;
  color: #645a5b;
}

.products__main__header{
  position:sticky;
  margin-bottom: 0;
}
.extra__div{
  color:#3d3839;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  padding: 25px;
  img{
      width: 20px;
      margin-right: 10px;
  }
}
.each__price__text{
  justify-content: center;
}
.product__partno__text{
  color: #0069a7 !important;
  &:hover{
    color: red !important;
  }
}
.product-item-dark-text{
  font-weight: 500;
  color: #3d3839;
}
@media only screen and (max-width: 767px) {
  .extra__div{
    height: 5px;
  }
  .product__partno__text{
    color: #0069a7 !important;
  }
  .products__table__header {
    display: none;
  }
  .products__upper__description {
    padding-top: 10px;
  }
  .mobile__price{
    font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.78px;
  color: #3d3839;
  }
  .each__price__text{
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: right;
    color: #898383;
    justify-content: flex-start;
  }
}
.cart__icon__containerrr {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  &:hover {
    background-color: red;
    padding-top: 0px;
    .cart__iconnn {
      color: white;
    }
  }
  .cart__iconnn {
    width: 25px;
    height: auto;
    color: #807879;
    &:hover {
      color: white;
    }
  }
}
.explore-product{
  &:hover
  {
    color: red;
  }
}
.product-view{
  &:hover{
    color: red !important;
  }
}

.all-product-quantity-input{
  background: transparent;
  border: 1px solid #645a5b;
}

@media screen and (max-width:991.98px) and (min-width:768px) {
  .products__table__header{
    grid-template-columns: 0.5fr 2fr 2.5fr 2.5fr 3fr 2fr 1.5fr 1fr;
  }
}



.product-item-container-updated{
  display: grid;
  border-left: solid 1px rgba(227, 227, 227, 0.57);
  border-right: solid 1px rgba(227, 227, 227, 0.57);
  color: #645a5b;
  border-bottom: solid 1px #ebebeb;
  grid-template-columns: 1.5fr 1.5fr 3fr 2fr 1.5fr 1.5fr 1.5fr;
  grid-template-rows: 1fr;
  grid-template-areas: 
    "item-checkbox item-image item-part item-product item-brand item-quantity item-price item-cart"
    ;
  background-color: white;
  padding: 1vh;
  img{
    width: 60px !important;
    height: 60px !important;
    object-fit: contain;
  }
}

.item{
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 14px; 
  justify-content: center;
  img{
      max-width: 100%;
  }
}

#item-checkbox{
  grid-area: item-checkbox;

  .item-product-checkbox{
      // height: 18px;
      // width: 18px;
  }
}

#item-image{
  grid-area: item-image;
  justify-content: center;

  .item-product-image{
      height: 90px;
      width: 90px;
  }
}

#item-part{
  grid-area: item-part;
  justify-content: center;
  word-break: break-all;
}

#item-product{
  grid-area: item-product;
  word-break: break-all;
}


#item-brand{
  grid-area: item-brand;
  word-break: break-all;
}

#item-quantity{
  grid-area: item-quantity;
  justify-content: center;

  .quantity__input__container{
      width: 80px;

      .quantity__input{
          width: 100%;
          text-align: center;
      }
  }
}

#item-price{
  grid-area: item-price;
  text-align: center;
  justify-content: center;
}

#item-cart{
  grid-area: item-cart;
  justify-content: center;

  .cart__icon__container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      cursor: pointer;
      &:hover {
        fill: white;
        background-color: red;
        .cart__icon {
          color: white;
          fill: white;
        }
      }
      .cart__icon {
        width: 25px;
        height: auto;
        color: #807879;
        &:hover {
          color: white;
        }
      }
    }

}
#item-total{
  grid-area: item-total;
  display: none;
}

.read-note{
  display: flex;
  flex-direction: column;
  }
  .read-note .read-more{
  color: #1d6aa8;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  }

#item-image,#item-part,#item-product,#item-brand,#item-quantity,#item-price,#item-checkbox{
  border-right: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .product-item-container-updated {
      margin-bottom: 5px;
      box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.03);
      grid-template-columns: 0.5fr 3.3fr 2.5fr 1.5fr;
      grid-template-rows: 1.5fr 0.75fr;
      grid-template-areas: 
          "item-checkbox item-image item-brand item-cart"
          "item-part item-part item-quantity item-total"
          ;    
      padding: 0px;
  }
  #item-total-quan{
      border-bottom: 1px solid #ebebeb; 
  }
  #item-image{
      .item-product-image{
      max-height: 41px;
      width: auto;
      }
    }
  
  #item-total{
    display: flex;
    justify-content: end;
    text-align: end;
  }
  
  #item-product{
    display: none;
  }
  
  #item-price{
  //   display: none;
  }

  #item-brand{
      padding: 5px 0;
  }   

  #item-image,#item-part,#item-product,#item-brand,#item-quantity,#item-price{
      // border-right: none;
    }

  #item-part,#item-quantity,#item-total{
      border-top: 1px solid #ebebeb;
  }

  #item-part{
      border-right: 1px solid #ebebeb;
  }
  #item-quantity{
    border-right: none;
  }
  .mobile-qty{
    display: block;
    font-size: 13px;
font-weight: bold;
line-height: 0.77;
color: #495057;
  }
  .item-single-price{
    display: flex;
    justify-content: center;
  }
  .item-total-price{
    justify-content: center;
    align-items: center;
  }
  .item-total-amount{
    margin-top: 5px
  }
} 

@media screen and (max-width:991.98px) and (min-width:768px) {
  .product-item-container-updated{
    grid-template-columns: 0.5fr 2fr 2.5fr 2.5fr 3fr 2fr 1.5fr 1fr;
  }
}

.data-not-found {
  text-align: center;
  padding: 20px 0px;
  border-bottom: 1px solid #ebebeb;
}
.order-status {
  background: #fcf0db;
  padding: 0 13px;
  opacity: 0.85;
  font-size: 12px;
  font-weight: 500;
  border-radius: 11px;
  color: #916c2e;
  &.status-0{
      color: #916c2e;
      background: #fcf0db;
      border: solid 1px #fbe9c9;
  }
}
.form-input{
    display: flex;
    flex-direction: column;
    label{
      font-weight: 500;
      font-size: 15px;
    }
    textarea{
      height: 200px;
      padding: 10px;
      border: 1px solid #ebebeb;
    }
    button{
        margin: 30px 0px;
    }
    .error-msg{
        color: red;
    }
}

.payment-container{
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  .payment-row{
    display: flex;
    justify-content: flex-start;
    .subtotal{
      min-width: 130px;
    }
  }
}

.card-1{
  margin-bottom: 5px !important;
}
.item-product-image{
  // max-width: 55px !important;
  width: 60px !important;
  height: 60px !important;
  object-fit: contain;
}
.order-footer{
  .warning {
    background-color: #fcf5e1;
    color: #ad8d52;
    padding: 10px 15px;
    img{
      width: 20px;
      margin-right: 10px;
    }
    .bold{
      font-weight: bold;
    }
  }
}
.cancel-btn{
  background-color: #655b5c;
  border: none;
  color: #ffffff;
  padding: 8px 15px;
  height: 37px;
  font-size: 14px;
  font-weight: 500;
}
.order-steps-container{
padding: 24px 29px;
.heading{
  margin-bottom: 20px;
  color: #3d3839;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
}
.order-steps{
  display: flex;
  justify-content: center;
  .step{
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0px 15px;
    width: 15%;
    &.step-line::before{
      content: "";
      height: 2px;
      background-color: #006aaa;
      width: 100%;
      position: absolute;
      top: 8px;
      right: 44%;
    }
    .step-desc{
      text-align: center;
      p{
        margin-bottom: 5px;
        line-height: normal;
        &.desc-heading{
          font-weight: bold;
          margin-top: 30px;
        }
        &.desc-date{
          font-size: 12px;
        }
        &.desc-name{
          margin-top: 10px;
          color: #367da1;
          font-weight: bold;
        }
      }
    }
    .bullet{
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: #006aaa;
      display: flex;
      justify-content: space-around;
    }
    &.in-active{
      .bullet{
        background-color: #d0d0d0;
      }
      &.step-line::before{
        background-color: #d0d0d0;
      }
    }
  }
}
}
.warning-container{
margin: 20px 0px 20px 0px !important;
.error-warning{
  padding: 20px;
  background-color: #fde1e1;
  font-size: 12px;
  .heading,i{
    color:#b37270;
    font-size: 18px;
  }
  i{
    margin-top: 5px;
  }
  .desc{
    font-size: 14px;
  }
  .content-area{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    button{
      border: 1px solid #b37270;
      background: transparent;
      color: #b37270;
      font-weight: 600;
      font-size: 15px;
      padding: 5px 10px;
    }
  }
}
}

.products__table__container{
  .extra__div{
    span{
      font-weight: 400;
      padding-top:2.5px;
      padding-bottom: 2.5px;
    }
  }
}

.wrapper-notes {

  .added-notes {
    max-height: 200px;
    overflow-y: auto;
    margin: 0 -10px;
    margin-bottom: 10px;
    padding: 10px;

    .box-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f5;
      padding: 10px;
      font-size: 12px;
      color: #4c5258;
      font-weight: 600;
      border: 1px solid #dbdbdb;
      border-style: dashed;
      border-radius: 3px;
      position: relative;
      margin-bottom: 10px !important;
      p {
        width: 85%;
      }
      &::after {
        content: '';
        border-bottom: 1px dashed #dbdbdb;
        border-right: 1px dashed #dbdbdb;
        background-color: #f5f5f5;
        position: absolute;
        left: 25px;
        bottom: -6px;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
      }
    }
  }
  
  .add-note {
    .form-input{
      display: flex;
      flex-direction: column;
      label{
        font-weight: 500;
        font-size: 15px;
      }
      textarea{
        height: 70px;
        padding: 10px;
        border: 1px solid #ebebeb;
      }
      .error-msg{
          color: red;
      }
  }
  .add-note-btn {
    margin-top: 10px;
    padding: 5px 15px;
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    font-size: 12px;
    font-weight: 500;
    background-color: #0069a7;
    border: none;
  }
  }
}