.inventory-dropdown-div{
    display: flex;
    align-items: center;
}
.warehouse-dropdown{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.select-warehouse{
    margin-right: 8px;
    font-size: 13px;
    font-weight: 600;
    color: #655b5c;
}
.drop--toggle{
    width: 297px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inventory-navbar{
    display: flex;
    justify-content: end;
}

.part-input-div{
    display: flex;
    align-items: center;
}
.part--input{
    width: 360px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #c4c4c4;
    background-color: white;
    font-size: 13px;
    font-weight: 500;
    color: #3d3839;
    padding-left: 18px;
    margin-left: 20px;
}
.part-text{
    font-size: 13px;
    font-weight: bold;
    color: #495057;
}
.inventory-search-btn{
    width: 90px;
    height: 40px;
    border-radius: 3px;
    background-color: #0069a7;
    font-size: 14px;
    font-weight: 600;
    color: white;
    margin-left: 10px;
    margin-bottom: 3px
}
.inventyory-part-div{
    display:flex ;
    align-items:center ;
    justify-content: space-between;
}
.total-quantity{
    font-size: 15px;
    line-height: 0.67;
    color: #495057;
}
.total-num{
    font-weight: bold;
    color: #0069a7;
}