.drop-zone-container{
    width: 515px;
    height: 110px!important;
    min-height: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .drop-zone-icon{
        font-size: 2rem;
    }
    h4{
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.91;
        letter-spacing: normal;
        text-align: center;
        color: #495057;
    }
}
.image-supported-text{
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.33px;
    color: #3d3839;
}
.description-height{
    height: 100px;
}

.global-settings-form-outer-dropzone{
    position: relative;
    max-width: 515px;
}

@media (max-width: 575.98px) { 
    .drop-zone-container{
        width: 100%;
        height: 100%!important;

    }
    .global-settings-card-body{
        flex-direction: column;
    }
 }