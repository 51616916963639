.discount-table-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discount-text{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #20253a;
}
.discount-export-btn {
  margin-right: 10px;
  width: 78px;
  height: 35px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  border: solid 1px #d0cece;
  background-color: var(--white);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}
.discount-create-btn {
  background-color: #0069a7;
  width: 150px;
  height: 35px;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  &:hover{
      opacity: 0.9;
  }
}
.table--head{
  background-color:rgb(236, 239, 247);
  
}
.del--icon{
  margin-left: 15px;
  cursor: pointer;
}
.discount-hr{
  margin: 0 !important;
  margin-top: 10px;
}
.input-check-box{
  cursor: pointer;
}
.modal--hr{
  margin: 0;
}
.discount-row{
  // padding: 20px 16px;
  align-items: center;
}
.modal-footer{
  padding: 14px 16px;
  justify-content: flex-start;
  padding-bottom: 24px;
}
.discount-code-row{
  margin-left: 14px;
}
.modal-heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
}
.modal-cross{
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.create-heading{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #20253a;
}
.discount-heading{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #20253a;
}
.discount-sub-heading{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #20253a;
}
.details--div{
  background-color: #ecf2f5;
  padding: 20px 30px 20px 20px;
}
.headind-disable{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #20253a;
}
.disable-details{
  opacity: 0.94;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #3d3839;
}
.disable-modal-row{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: #3d3839;
}
.discount__code{
  font-weight: 500;
  color: #645a5b;
}
.disable-yes-btn{
  width: 78px;
  height: 35px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  background-color: #d54242;
  border:none;
  color: white;
}
.disable-no-btn{
  background-color: #655b5c;
  margin-left: 20px;
}
.disable-cross-icon{
  float: right;
  padding-right: 5px;
  padding-top: 5px;
  width: 20px;
  height: 20px;
}


@media (max-width: 575.98px){
  .discount-dropdown-container{
    margin: 10px 0;
  }
}
