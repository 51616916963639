$color_1: #3D3839;
$color_2: #B05959;
$color_3: #645A5B;
$color_4: #0069A7;
$color_5: #FFF;
$font-family_1: 'Montserrat';
$background-color_1: transparent;
$background-color_2: #ECECEC;
$background-color_3: #ED2C2B;

.wrapper-account-statement {
    min-height: 600px;

    // @media only screen and (max-width:992px) {
    // 	width: 600px;
    // 	overflow-x: auto;
    // }
}

.header-account-statement {
    padding: 0 9px;
}

.close-btn-account-statement {
    display: flex;
    justify-content: end;

    button {
        border: none;
        background-color: $background-color_1;
        padding-bottom: 17px;
    }
}

.datepicker-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 195px;
    height: 40px;
    border: 1px solid #BCB7B8;
    padding: 10px 12px;
    border-radius: 5px;

    input {
        border: none;
        color: $color_1;
        font-weight: 500;
        font-size: 14px;
        width: 100%;
    }
}

.datepicker-icon {
    cursor: pointer;
}

.inner-header-account-statement {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
    }

    .inner-header-right-container {
        display: flex;

        @media only screen and (max-width:992px) {
            display: block;

            .location {
                padding-bottom: 15px;
            }
        }
    }

    @media only screen and (max-width:768px) {
        display: block;

        .bill-cycle {
            padding-bottom: 15px;
        }

        .inner-header-right-container {
            display: flex;
        }

        .location {
            padding-bottom: 0;
        }
    }

    @media only screen and (max-width:575px) {
        .inner-header-right-container {
            display: block;
        }
    }
}

.acc-statement {
    font-family: $font-family_1;
    color: $color_1;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 27px;
}

.bill-cycle {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $color_2;
    padding-top: 5px;
}

.table-account-statement {
    padding: 20px 10px 0 10px;

    @media only screen and (max-width:991px) {
        width: 600px;
        overflow-x: auto;
    }
}

.header-table-account-statement {
    display: grid;
    grid-template-columns: 1fr .8fr 1fr .8fr;
    background-color: $background-color_2;
    padding: 12px;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $color_3;
}

.row-table-account-statement {
    display: grid;
    grid-template-columns: 1fr .8fr 1fr .8fr;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $color_1;
    border-bottom: 1px solid #F0F0F0;
}

.statement-column {
    button {
        color: $color_4;
        font-weight: 500;
        font-size: 14px;
        background: transparent;
        border: none;
    }
}

.btn-pay {
    button {
        border: none;
        background-color: $background-color_3;
        padding: 6px 10px;
        color: $color_5;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 1px;
    }
}

.paid-status {
    background: #E1F3DC;
    border-radius: 12px;
    text-align: center;
    width: 60px;
    height: 24px;
}

.pending-status {
    background: #F8F9E6;
    border-radius: 12px;
    text-align: center;
    width: 91px;
    height: 24px;
}

.partial-pending-status {
    background: #FAE5AF;
    border-radius: 12px;
    text-align: center;
    width: 109px;
    height: 24px;
}

.due-status {
    background: #e6f1f8;
    border-radius: 12px;
    text-align: center;
    width: 60px;
    height: 24px;
}

.account-statements-wrapper {
    color: #0069a7;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .8px;
    line-height: 22px;
    padding-right: 40px;

    img {
        padding-right: 10px;
    }

    :hover {
        text-decoration: underline;
    }
}

.invoice-float-btn {
    .product-selected-item {
        width: 228px;
        height: 39px;
        border-radius: 2px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.45);
        background-color: #ed2c2b;
        border: none;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: -0.1px;
        text-align: center;
        color: #fff;
    }

    .product-item-selected-row {
        top: 13vh;
        right: 10%;
        z-index: 1;
        text-align: right;

        &.product-management {
            right: 350px;
            top: 93px;
        }
    }

    .products__quantity {
        position: absolute;
        width: 33px;
        height: 33px;
        box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.21);
        background-color: $color_5;
        border-radius: 50%;
        right: -15px;
        top: -15px;
        color: #ed2c2b;
        font-size: 12px;
        font-weight: 600;
    }
}

.active-note {
    color: #508829;
}