.add-new-customer-btn {
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #d54242 !important;
    font-size: 14px;
    color: white;
    padding: 8px 10px;
    border: none;
  }
  .export-btn {
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    border: solid 1px #d0cece;
    background-color: white;
    padding: 8px 10px;
    font-size: 14px;
  }
  .stop-icon {
    color: #807879;
    font-size: 20px;
  }
  .disabled-icon {
    color: #807879;
    font-size: 20px;
  }
  .eye-icon {
    color: #807879;
    font-size: 20px;
    margin-right: 5px;
  }
  .active-div {
    border-radius: 11px;
    border: solid 1px #ceebbb;
    background-color: #def2d0;
    text-align: center;
    padding: 0px 10px;
    width: fit-content;
    color: #508829;
    letter-spacing: -0.2px;
    font-size: 12px;
  }
  .pending-div {
    border-radius: 11px;
    border: solid 1px #fbe9c9;
    background-color: #fcf0db;
    text-align: center;
    padding: 0px 10px;
    width: fit-content;
    color: #916c2e;
    letter-spacing: -0.2px;
    font-size: 12px;
  }
  .rejected-div {
    border-radius: 11px;
    border: solid 1px #fcd2d2;
    background-color: #fde1e1;
    text-align: center;
    padding: 0px 10px;
    width: fit-content;
    color: #924040;
    letter-spacing: -0.2px;
    font-size: 12px;
  }
  .disabled-div {
    border-radius: 11px;
    border: solid 1px #f4f4f4;
    background-color: #f4f4f4;
    text-align: center;
    padding: 0px 10px;
    width: fit-content;
    color: #495057;
    letter-spacing: -0.2px;
    font-size: 12px;
  }
  .approved-div {
    border-radius: 11px;
    border: solid 1px #eadf97;
    background-color: #fbf2b8;
    text-align: center;
    padding: 0px 10px;
    width: fit-content;
    color: #7d6e06;
    letter-spacing: -0.2px;
    font-size: 12px;
  }
  
  .customer-container {
    .card-body {
      .nav-pills {
      }
      .nav-link {
        padding-bottom: 15px;
  
        &.active {
          color: #d54242;
          background: none;
          border-bottom: 2px solid #d54242;
          border-radius: 0;
        }
      }
    }
  }
  .dropdown {
    .customer-select {
      padding: 0px 15px;
      border-radius: 2px 0 0 2px !important;
      border: solid 1px #d0cece !important;
      background-color: #f9f9f9 !important;
      color: #767676 !important;
      height: 34px;
      span {
        font-weight: 600;
      }
    }
  }
  
  .customer-search-form {
    position: relative;
    width: 190px;
    height: 34px;
  
    .customer-input-border {
      border: solid 1px #d0cece;
      border-radius: 0 2px 2px 0;
      height: 34px;
    }
  }
  .active-customer-upper-card {
    div {
      flex: 1;
      text-align: center;
      border-right: solid 1px #979797;
      &:nth-last-child(1) {
        border: none;
      }
      p {
        font-size: 14px;
        line-height: 2.36;
        text-align: center;
        color: #645a5b;
      }
      span {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #3d3839;
      }
      .span-blue {
        font-size: 22px;
        font-weight: bold;
        color: #0069a7;
      }
      .para-blue {
        font-size: 12px;
        font-weight: 500;
        color: #0069a7;
        margin: 0;
      }
    }
  }
  .customer-notes-label {
    font-size: 14px !important;
    text-align: center;
    color: #3d3839 !important;
  }
  
  .dripicons-arrow-thin-left {
    font-size: 20px;
    font-weight: 600;
    padding-top: 6px;
  }
  
  .bottom-customer-card-container {
    .card-body {
      .nav-pills {
      }
      .nav-link {
        font-size: 14px;
        font-weight: 500;
        margin-right: 15px;
        padding-bottom: 15px;
  
        &.active {
          color: #d54242;
          background: none;
          border-bottom: 2px solid #d54242;
          border-radius: 0;
          font-weight: 500;
        }
      }
    }
    .add-new-btn {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.93;
      color: #0069a7;
      border: none;
      background: none;
    }
    .bottom-customer-card-inner-location {
      height: auto;
      width: 80%;
      margin: auto;
  
      .location-number {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.44;
        color: #3d3839;
      }
      .primary-location {
        margin-left: 10px;
        border-radius: 11px;
        border: solid 1px #eadf97;
        background-color: #fbf2b8;
        color: #7d6e06;
        font-size: 12px;
        letter-spacing: -0.2px;
        padding: 2px 5px;
      }
      .bx,
      .mdi {
        font-size: 20px;
        margin-left: 10px;
      }
      .location-details-container {
        border-radius: 3px;
        margin: 10px 0;
        border: solid 1px #aad2fb;
        padding: 15px;
        .edit-btn {
          font-size: 14px;
          font-weight: 600;
          color: #0069a7;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .bxs-edit-alt {
          font-size: 16px;
          margin-right: 10px;
        }
        .location-container-inner {
          flex: 1;
          border-right: solid 1px #979797;
          &:nth-last-child(1) {
            border: none;
          }
        }
        p {
          margin: 0;
        }
        .location-details-big-heading {
          font-size: 16px;
          line-height: 1.86;
          color: #3d3839;
          font-weight: bold;
        }
        .location-details-heading {
          font-size: 14px;
          line-height: 1.86;
          color: #3d3839;
          font-weight: bold;
        }
      }
    }
  }
  
  .location-modal-select-container {
    .location-modal-select {
      width: 100%;
      background: none !important;
      border: 1px solid #ced4da !important;
      text-align: left !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .btn-secondary {
      color: #3d3839 !important;
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  
  .location-modal-cancel-btn {
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #645a5b;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 10px;
  }
  .location-modal-save-btn {
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #d54242;
    color: white;
    border: none;
    padding: 5px 10px;
  }
  
  .approve-btn{
    color: white;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #367940;
    border: none;
    padding: 5px 20px;
    font-size: 14px;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  
  .reject-btn{
    color: white;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #d54242;
    border: none;
    margin-right: 10px;
    padding: 5px 20px;
    font-size: 14px;
  }
  
  .location-modal-body{
    height: 85vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #353535;; 
      border-radius: 10px;
    }
  }


  .bx-sort,.sorting-arrow{
    font-size: 18px;
    margin-left: 5px;
    cursor: pointer;
  }

  .empty-search-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    cursor: pointer;
  }

  .input-sell-price{
    width: 100px;
    height: 35px;
}

.input-icon-cross{
  font-size: 24px;
  color: #c4ca61;
}

.input-icon-tick{
  font-size: 24px;
  color: #558135;
}