.new-registration-container {
    width: 80%;
    margin: auto;
    .new-registration-heading-blue {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        color: #0069a7;
        // margin: 15px 0 25px 0;
    }
    .new-registration-heading-black {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.44;
        color: #3d3839;
    }
    .new-registration-normal-heading {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.44;
        color: #3d3839;
    }
    .warehouse-location-heading {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.63;
        color: #0069a7;
    }
    label {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.86;
        color: #645a5b;
    }
    .category-subcategory-container {
        border-radius: 3px;
        border: solid 1px #807879;
        height: 225px;
        padding: 10px 20px;
        overflow-y: scroll;
        .subcategory-container {
            margin-left: 25px;
            display: flex;
            align-items: center;
        }
    }
    .new-wd-select-container {
        .new-wd-select {
            width: 100%;
            background: none !important;
            border: 1px solid #807879 !important;
            text-align: left !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .btn-secondary {
            color: #3d3839 !important;
            &:focus {
                box-shadow: none !important;
            }
        }
    }
    input {
        border: solid 1px #807879;
    }
    .new-wd-add-new-btn {
        border: none;
        background: none;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.93;
        color: #0069a7;
        display: flex;
        align-items: center;
        width: 100% !important;
        justify-content: end;
    }
    .new-wd-approval-btn {
        border: none;
        border-radius: 3px;
        background-color: #d54242;
        color: white;
        padding: 10px 15px;
        margin-right: 15px !important;
        font-size: 14px;
        font-weight: 600;
        &:hover{
            background-color: #b53838;
        }
    }
    .new-wd-reset-btn {
        border: none;
        border-radius: 3px;
        background-color: #645a5b;
        color: white;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 600;
        &:hover{
            opacity: 0.9;
        }
    }
    .bx-plus,.bx-minus{
        font-size: 20px;
    }
}
.cursor--p{
    cursor:pointer;
}
.cloud-icon{
    font-size: 26px;
}
.choose-cloud-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-newbtn-hover{
   &:hover{
    color: #d54242;
   }
    cursor: pointer;
}
