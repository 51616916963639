.status-main-div{
    display: flex;
}
.profile-top-div{
    height: 92px; 
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
    background-color: white;
    margin-top: 22px;
    display: flex;
}
.registration-date{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal; 
    font-style: normal;
    line-height: 1.88;
    color: #645a5b;
}
.warehouse-date{
    font-size: 18px;
    font-weight: 700;
    line-height: 1.39;
    color: #3d3839;
}
.warehouse-register-div{
    flex: 1;
    text-align: center;
    margin: auto 0;
}
.border-div{
    border-left:  solid 1px #979797;
    border-right: solid 1px #979797;
    height: 62px;
}
.buisness-information-div{
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02); 
    background-color: white;
    height: auto;
    margin-top: 15px;
    padding-bottom: 20px;
}
.inner-div{
    width: 71.85%;
    margin-top: 2.4rem;
    height: 500px;
    margin: 0 auto;
}
.business-edit-div{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}
.buisness-text{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.63;
    color: #0069a7;
    cursor: pointer;

}
.buisness-text-edit{
    font-size: 18px;
    &:hover{
        color: #d54242;
    }
}
.warehouse-address-div{
    display: flex;
    margin-top: 28px;
}
.warehouse--address{
    width: 49.5%;
}
.warehouse-vl{
    border-left: solid 1px #979797;
    height: 298px;
}
.warehouse-contact{
    width: 60%;
    margin: 0 auto;
}
.warehouse-street{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal; 
    font-style: normal; 
    line-height: 2; 
    letter-spacing: normal; 
    color: #655b5c;
}
.warehouse-street-name{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #3d3839;
}
.warehouse-contact-text{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700; 
    font-stretch: normal; 
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #3d3839;
}
.street-div{
    margin-top: 14px;
}
.contact-warehouse-left{
    width: 60%;
    margin: 0 auto;
}
.td-icon{
    display: flex;
}
 

      // EDIT PROFILE
 
.edit-profile-div{
    display: flex;
    // justify-content: space-evenly;
    justify-content: space-between;
    padding: 0 50px;
    padding-top: 36px;
}
.form-shipping-width{
    width: 48%;
}
.warehouse-input{
    width: 99%;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #c4c4c4;
    background-color: var(--white);
    padding-left: 20px;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 700; 
    font-stretch: normal;
    font-style: normal; 
    line-height: 2; 
    letter-spacing: normal;
    color: #3d3839;
}
.req-btn{
}
.pointer{
    cursor: pointer;
}
.warehouse-location{
    padding: 20px 46px 0 46px;

}
.add--newlocation{
    margin-top: 0 !important;
    margin-bottom: 22px;
}
.pointer-cursor{
    cursor: pointer;
}
.warehouse-cancle-btn{
    width: 83px;
    height: 40px;
    border-radius: 3px; 
    background-color: #645a5b;
    margin-left: 15px;
}
.warehouse-req-btn{
    width: 157px;
    height: 40px;
    border-radius: 3px;
    background-color: #d54242;
    border: none;
    font-family: Montserrat;
    font-size: 14px; 
    font-weight: 700;
    color: white;
}
.warehouse-cancle-btn{
   
     width: 83px;     
    height: 40px;
     border-radius: 3px;    
    background-color: #645a5b;
    font-family: Montserrat; 
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
    border: none;
}
.btn-main--div{
    margin-top: 30px;
    padding: 0 50px;
}
.street--main--div{
    margin-top: 15px;
}
.warehouse--first-name{
    display: flex;
    justify-content: space-between;
}
.name--first{
    width: 48%;
}



.import--btn{

  width: 78px;
  height: 35px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  border: solid 1px #d0cece;
  background-color: var(--white);
  margin-right: 10px;
  &:hover{
      opacity: 0.9;
  }
}
.add-btn-warehouse{
    width: 192px !important;
}
.new-drop-state{
    width: 99%;
}


//Disable_Module//
.modal-detail-div{
    width: 420px;
    height: 100%;
    opacity: 0.94;
    margin: 0 auto;
    border-radius: 6px;
    background-color: #ecf2f5;
    padding: 20px;
}
.disable-warehouse{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
    text-align: center;
    color: #20253a;
}
.modal-details{
    opacity: 0.94;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #3d3839;
    margin-bottom: 0 !important;
}
.modal-warehouse-name{
    opacity: 0.94;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 2.14;
    color: #3d3839;
    margin-bottom: 0 !important;

    a {
        color: #0069a7;
    }
}
.warehouse-btn-yes{
    width: 78px;
    height: 35px;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #d54242;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border: none;
    &:hover{
        background-color: #b63a3a;
    }
}
.customer-select{
    width: auto ;
}
.warehouse-btn-no{
    width: 78px;
    height: 35px;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color:  #655b5c;
    font-family: Montserrat;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    border: none;
    &:hover{
        opacity: 0.9 !important ;
    }
}
.calender-img{
    position: absolute;
    top: 10px !important;
    right: 16px;
}

.modal-btn-div{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.modal-header{
    border-bottom: 0 !important;
}
.warehouse-heading{
    font-weight: 500;
    color: #645a5b;
}
.warehouse-navbar{
display: flex;
justify-content: space-between;
}

@media only screen and (max-width:1235px) {
    .warehouse-navbar{
        flex-direction: column;
        row-gap: 10px;
    }
    .product_nav{
        margin-top: 10px;
    }
    .drop-search{
        margin-top: 8px;
        
    }
    .tab-hr{
        margin-top: 8px !important;
    }
    .wd-bottom{
        border-bottom: 1px solid #adb5bd;
    }
} 
@media only screen and (max-width:540px) {
    .drop-search{
        flex-direction: column;
    }
    .modal-detail-div {
        width: 295px;
    }
    .product_nav{
        flex-direction: column;
    }
    .warehouse-address-div{
        flex-direction: column;
    }
    .warehouse-vl{
        border: none;
        height: auto;
    }
    .inner-div{
        height: auto;
    }
    .warehouse-contact {
        margin: 0;
        margin-top: 15px;
    }
    .contact-warehouse-left{
        margin: 0;
    }
    .warehouse--address {
        width: 100%;
    }
    
}
.choosefile--hover{
    &:hover{
        background-color: #adb5bd;
    }
}