.main-static-header {
    width: 100%;
    height: 80px;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    border: solid 1px rgba(227, 227, 227, 0.57);
    background-color: #fff;
    @media only screen and (max-width: 480px) {
      justify-content: center;
    }
  }
  .sign-link {
    color: #0069a7;
  }
  .register-header {
    margin-right: 20px;
  }
  .static-header {
    max-width: 950px;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      width: 800px;
    }
    @media only screen and (max-width: 780px) {
      justify-content: center;
    }
  }
  .qls-img {
    @media only screen and (max-width: 480px) {
    }
  }
  .new-qls {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #645a5b;
  }
  .header-register {
    color: #0069a7;
  }
  .already-account {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    text-align: right;
    color: #0069a7;
    @media only screen and (max-width: 780px) {
      display: none;
    }
  }


//   StaticFooter

.main-footer{
    width:100%;
    height: 120px;
    background-color: #fff;
    align-items: center;
    margin-top: 10px;
    @media only screen and (max-width:480px) {
       height: 100px;
    }
}
.privacy-security{
    display: flex;
    justify-content:center;
    // margin-top: 1.5em;
    // align-items: center;
}
.privacy-div{
    width: 50%;
    text-align: center;
    margin: 1.5em 0px;
    @media only screen and (max-width:480px) {
        font-size: 13px;
        width: 100%;
    }
}
.privacy-span{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0069a7;
    margin:20px;
    @media only screen and (max-width:480px) {
        margin: 7px;
    }

}
.sonuu{
    display: flex;
    height: 100px;
    background-color: chartreuse;
    justify-content: center;
    align-items: center;
}
.copyright{
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #645a5b;
}