.heading-tab{
    background: #d9e0ef;
    padding: 15px 15px;

    p{
        margin-bottom: 0px;
        font-weight: 600;
    }
    &.light{
        background: #eceff5;
    }
    @media only screen and (max-width: 576px) {
        width: 150px !important;
    }
}

.input-tab{
    background-color: #f6f6f6;
    padding: 15px;
    &.light{
        background: #ffffff;
    }
    @media only screen and (max-width: 576px) {
        width: 150px !important;
    }
}

.table-card{
    @media only screen and (max-width: 576px) {
        overflow-x: scroll;
        .table-row{
            flex-wrap: nowrap !important;
        }
    }
}
.fas{
    cursor: pointer;
}

.new-form-search{
    width: 350px;
}