.rc-time-picker-panel-select{
    overflow-y: scroll!important;
    border: 1px solid #f3f0f1;
    &::-webkit-scrollbar {
        width: 15px;
        height: 15px;
        
      }

      &::-webkit-scrollbar-track {
        background: #f3f0f1;
        cursor: pointer!important;
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer!important;
        background-color: #3d3839;
        border-radius: 10px;
        border: 3px solid #f3f0f1;
      }
}
.time-picker{
    width: 100%;
    input{
        width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    }
}

.time-picker-popup{
    width: 100%;
    background: transparent;
   
    max-width: fit-content!important;
    .rc-time-picker-panel-select{	
        width: 70px!important;
        ul{
            background-color: white!important;
            min-height: 144px!important;
            border-left: 1px solid #f3f0f1!important;
        }
    }
    .rc-time-picker-panel-input-wrap{
        height: 37px!important;
        visibility: hidden;
    }
    .rc-time-picker-panel-inner{
        margin-top: 3px!important;
        margin-left: 1px!important;
        box-shadow: none!important;
        border: none!important;
        background-color: transparent!important;
    }        
}