.wd-edit-modal-body {
    padding: 0px 20px 20px;
    input {
        border: solid 1px #807879;
    }
    .edit-location-heading {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.44;
        color: #3d3839;
    }
    .section-heading {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.63;
        color: #3d3839;
    }
    .wd-edit-location-container {
        .wd-edit-location-select {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white !important;
            color: #74788d !important;
            &:focus {
                box-shadow: none;
            }
        }
    }
    .wd-location-modal-cancel-btn {
        border-radius: 3px;
        box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
        background-color: #645a5b;
        color: white;
        border: none;
        padding: 5px 10px;
        margin-right: 10px;
        width: 100px;
        &:hover{
            opacity: 0.9;
        }
    }
    .wd-location-modal-save-btn {
        border-radius: 3px;
        box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
        background-color: #d54242;
        color: white;
        border: none;
        padding: 5px 10px;
        width: 100px;
        &:hover{
            opacity: 0.9;
        }
    }
}
.cursor-pointer{
    cursor: pointer;
}
.tr-flex{
    display: flex !important;
}
.tooltip-main{
    position: relative;
}
.tooltiptext{
    // visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 110%;
}
.tooltiptext::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent red;
}
// .tooltip-main:hover .tooltiptext {
//     visibility: visible;
//   }
.warehouse--staff{
    margin: 0 3em;
    display: flex;
    align-items: center;
}
.main-drop-staff{
    width: auto;
    
}
.drop-staf{
    width: 100%;
    height: fit-content;
    margin-left: 20px;
    background-color: white;
    // -webkit-appearance: none;
    padding-left: 14px;
    -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 0.57em;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
padding: 0.75em;
  padding-right: 2rem;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  color: #3d3839;
}
.dropdown__staff{
    position: relative;
}
.dwon-arrow{
    position: absolute;
}
.staff-main-table{
    margin: 0 3em;
    margin-top: 16px;
}
.input-label{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
}
.sidebar--li{
    &:hover{
        background-color: #005d95;
    }
    a.active{
        background-color: #005d95;
    }
}
.nav-link{
    &:hover{
        color: black !important;
    }
}
.export-btn{
    &:hover{
        background-color: #d0cece !important;
    }
}
.table-icon-hover{
    width: 27px;
    height: 28px;
    padding-left: 3.5px;
    border-radius: 2px;
    &:hover{
        background-color: #e7e7e7;
    }
}
.table-img-hover{
  
    border-radius: 2px;
    padding: 6px 6px 6px 6px  ;
    &:hover{
        background-color: #e7e7e7;
    }
}
.table-enable-hover{
    width: 27px;
    height: 28px;
    padding-left: 3.5px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    &:hover{
        background-color: #e7e7e7;
    }
}
.pagination-arrow-hover{
    &:hover{
        background-color: #d1cbcb ;
    }
}
#pagination-hover{
    &:hover{
        opacity: 0.9;
    }
}
.breadcrumb-main-nav {
display: flex;
align-items: center;
justify-content: space-between;
}
@media only screen and (max-width :540px) {
    .breadcrumb-main-nav {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start; 
    }
    .imp-exp-div{
        margin-top: 10px;
    }
    .customer-search-form{
        margin-top: 6px;
        width: 100%;
    }
    .customer-select{
        width: 100% !important;
    }
}
@media only screen and (max-width:480px) {
 
}


input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.wd-brands-container{
    border-radius: 3px;
    border: solid 1px #807879;
    height: 225px;
    padding: 10px 20px;
    overflow-y:hidden;
    .check-listing-container{
        overflow-y: scroll;
        height: 160px;
        .check-listing{
            .checkbox-line{
                margin-bottom: 4px;
            }
        }
    }
}
.loc-dropdown{
    display: flex;
    align-items: center;
}
.warehouse--staff-new{
    justify-content:space-between;
}
.filter--main--div{
    display: flex;
}
.drop-order{
    width: 350px;
    margin-bottom: 20px;
}