.btn-add-new {
  width: 100px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  background-color: $red;
  margin-left: 15px;
}
.btn-view-categories{
  width: max-content;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  margin-left: 15px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  border: solid 1px #d0cece;
  background: white;
}
.icons {
  font-size: 18px;
  color: #9a9394;
}

.search-form {
  width: 400px;
  position: relative;
}
.pagination-box {
  width: 27px;
  height: 30px;
  background-color: #f0f0f0;
  margin-right: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-box-active {
  background-color: #0069a7;
  color: white;
}

.search-icon {
  position: absolute;
  font-size: 18px;
  color: #9a9394;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-cancel {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  border: solid 1px #d0cece;
  background-color: white;
  margin-right: 10px;
  color: #645a5b !important;
  font-size: 14px;
  width: 100px;
  &:hover {
    color: white !important;
  }
}

.category-card-container {
  height: 473.6px;
  overflow: none;
}

.summary-text {
  font-size: 12px;
  line-height: 1.33;
  color: #797979;
  margin-top: 10px;
}

.input-border {
  border: solid 1px #807879;
}
.drop-zone-container-left {
  width: 100%;
  height: 130px !important;
  min-height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  .drop-zone-icon {
    font-size: 2rem;
  }
  h4 {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.91;
    letter-spacing: normal;
    text-align: center;
    color: #495057;
  }
}

.services-dropZone-container{
  .drop-zone-container-left {
    width: 100%;
    height: 200px !important;
    min-height: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    .drop-zone-icon {
      font-size: 2rem;
    }
    h4 {
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.91;
      letter-spacing: normal;
      text-align: center;
      color: #495057;
    }
  }
}

.crumb-container {
  font-size: 11px;
  letter-spacing: normal;
  color: #645a5b;
  background-color: #ebebeb;
  width: fit-content;
  margin: 5px 10px 5px 0;
  padding: 2px 10px;
  border-radius: 3px;
  .close-icons {
    font-size: 14px !important;
  }
}

.search-check-container {
  width: 100%;
  height: 188px;
  
  margin-top: 10px;
  border-radius: 3px;
  border: solid 1px #807879;
  .search-check-inner-container{
    overflow-y: scroll;
    height: 130px;
  }
}

.search-form-w100 {
  width: 100%;
  position: relative;
}

@media (max-width: 575.98px) { 
  .search-form {
    width: 100%;
  }
  .knowledge-base-button-container{
    flex-wrap: wrap;
    .btn-view-categories{
      margin: 0;
      margin-top: 10px;
    }
    .btn-add-new{
      margin: 0;
      margin-top: 10px;
    }
  }
}

.tox-edit-area__iframe{
  z-index: 0;
}

.tox-tinymce {
  border: solid 1px #807879!important;
  border-radius: 5px!important;
}