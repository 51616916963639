.create-discount-inner-container{
    width: 60%;
    padding: 20px;
    margin: auto;
}

.code-link{
    color:#216aa7;

    &:hover{
        color:#d54242;
        cursor: pointer;
    }
}

input[type="time"]{
    display: inline-block;
    position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.date-input{
    position: relative;

    i{
        position: absolute;
        font-size: 18px;
        top: 7.5px;
        right: 7.5px;
    }
}

input[type="date"]{
    display: inline-block;
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.percent{
    position: relative;
    width: fit-content;

    &::after{
        content: "%";
        font-size:14px;
        color:#969696;
        position: absolute;
        top: 7.5px;
        right:calc(20% + 5px);
    }
}

.dollar{
    position: relative;
    width: fit-content;

    &::before{
        content: "$";
        font-size:14px;
        color:#969696;
        position: absolute;
        top: 7.5px;
        left:5px;
    }

    .form-control {
        padding: 0.47rem 1rem;
    }
}

.w-40{
    width:80%
}

.set-float{
    display: inline-block;
    width:47.5%;
    margin-bottom: 1rem;
}

.validation-check{
   color: #d54242;
   font-size:12px;
}

.mg-l{
    margin-left: 5%;
}

.search-list{
    margin-top:30px;
    h4{
        font-size:18px;
        font-weight:600;
        border-bottom:1px solid #495057;
        margin-bottom: 0px;
        padding-bottom: 7.5px;
    }

    table{
        width:100%;
        tr{
            border-bottom:1px solid #495057;
            
            td{
                padding-top:10px;
                padding-bottom: 10px;
            }
        }
    }
}

.search-popup{

    .modal-header{
        border-bottom: 1px solid #dcdcdc !important;

        .modal-title{
            font-size:18px;
            font-weight: 500;
        }
    
        .close{
            font-size:16px;
            margin-top: 7.5px;
            margin-right:10px
        }
    }

    .modal-body{

        padding:0px;

        .search-bar{

            border-bottom: 1px solid #dcdcdc;
            padding: 15px 0px;

            .input-border{
                width:calc(100% - 2rem);
                margin-left:1rem;
            }
        }

        .modal-detail-div{
            background: transparent;
            width:100%;
            padding:0px;

            table{
                width:100%;
                tr{
                    td{
                        padding: 15px 0px;
                        font-size:18px;
                        font-weight: 600;
                        vertical-align: middle;
                    }

                    border-bottom: 1px solid #dcdcdc;
                }
            }
        }

        .modal-btn-div{
            justify-content: flex-end;
            padding-right:15px;

            .add-btn{
                margin-left:15px
            }
        }

    }

}