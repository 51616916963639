.notification-header {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
    color: #20253a;
    font-size: 20px;

    span {
      font-size: 14px;
    }
  }

  button {
    border: none;
    background: transparent;
    text-decoration: underline;
    font-size: 12px;
  }
}
.wrapper-notification {
  height: 240px;
  overflow-y: auto;
  margin: 0;
  padding: 0;

  a {
    color: #495057;

    &:hover {
      color: #495057;

      .inner-notification {
        background-color: #f8f9fa;
      }
    }
  }

  .empty-notification {
    display: flex;
    width: 200px;
    margin: auto;
  }

  .inner-notification {
    width: 100%;
    list-style: none;
    display: flex;
    gap: 15px;
    padding: 10px 15px;
    border-bottom: 1px solid #eff2f7;

    .notification-type {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 10px;
      background: #d54242;
      font-size: 20px;
      color: white;
    }

    .notification-details {
      width: calc(100% - 50px);
      .title {
      }
      .action-time {
        display: flex;
        gap: 10px;
        margin: 0;

        .tag {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          border: 1px solid #eadf97;
          background-color: #fbf2b8;
          padding: 0 6px;
          color: #7d6e06;
          font-size: 11px;
        }
      }
    }
  }
}
