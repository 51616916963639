@mixin scrolls9{
    &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
        background: #807879; 
    } 
    &::-webkit-scrollbar-thumb:hover {
        background: #807879; 
      }
}
// homepage
body{font-family: 'Montserrat', sans-serif !important;}
.outer-home-menu {
    background-color: #fafafa;
    @media only screen and (max-width:1199px){
        width: 100%;
        overflow-x: scroll;
        &::-webkit-scrollbar{
            height: 4px;
            width: 4px;
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb:horizontal{
            background: #807879;
            border-radius: 10px;
        }
    }
}
.homepage-wrap{    
    .card-body{
      padding: 10px;
      @media only screen and (max-width:1199px){
          padding: 20px;
      }
      @media only screen and (max-width:599px){
        padding: 10px;
      }
      .nav-pills{
          margin-right: 40px;
          display: block;
          width: 100%;
          background-color: #fafafa;
          max-height: 1500px;
          @media only screen and (max-width:1199px){
              max-height: unset;
              display: flex;
              width: 1480px;
          }
          @media only screen and (max-width:767px){
            width: 1290px;
          }
        .nav-link{
            padding: 13px 0 13px 13px;
            font-size: 14px;
            line-height: 24px;
            color: #495057;
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            background-color: #fafafa;
            border-radius: 0;
            border-bottom: 1px solid #dedede;
            @media only screen and (max-width:1199px){
                padding: 13px;
            }
            @media only screen and (max-width:767px){
                font-size: 12px;
                padding: 8px 13px;
            }
            &.active{
                background-color: #fff;
                color: #3d3839;     
                font-weight: 600;           
            }
        }
        @media only screen and (max-width:1199px){
            .nav-item{
                .nav-link{
                    border-left: 1px solid #dedede;
                }
                &:first-child{
                    .nav-link{
                        border-left: 0;
                    }
                }
            }
        }
      }
    }
}
.home-banner-right-wrap{
    margin-top: 32px;
    margin-right: 15px;
    h3{
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        line-height: 16px;
        font-weight: 700;
        text-transform: uppercase;
    }
    h4{
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;       
    }
    .upload-img-lines{
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.33px;
        color: #3d3839;
        font-weight: 400;
        margin-top: 12px;
        @media only screen and (max-width:767px){
            font-size: 11px;
            line-height: 15px;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                margin-bottom: 3px;
            }
        }
    }
    .dropzone{
        min-height: unset;
        border-radius: 3px;
        border: 1px dashed #979797;
        h5{
            font-size: 11px;
            line-height: 10px;
            color: #495057;
        }
        .dz-message{
            padding: 27px 30px;
        }
        .upload-icon{
            height: 58px;
            .text-muted {
                color: #d0cece !important;
            }
        }
        
    }
    .home-banner-inner{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        @media only screen and (max-width:599px){
            flex-wrap: wrap;
        }
        .home-banner-left-box, .home-banner-right-box{
            width: 48.5%;
            @media only screen and (max-width:599px){
                width: 100%;
            }
            &.sr-right{
                .form-outer-dropzone{
                    max-width: 220px;
                    .dropzone{
                       .dz-message{
                            padding: 27px 10px;
                       }
                    }
                }
            }
        }
        @media only screen and (max-width:599px){
            .home-banner-left-box{
                margin-bottom: 20px;
            }
        }
    }
    .home-banner-outer{
        border-bottom: 1px solid #eceff5;
        margin-bottom: 10px;
        &.sr-outer{
            margin-bottom: 33px;
            padding-bottom: 10px;
        }
    }
    .remove-add-banner{
        margin-bottom: 22px;
        display: flex;
        justify-content: space-between;
        .max-upload{
            font-size: 13px;
            line-height: 16px;
            font-weight: 500;
        }
        .add-rem-btns{
            margin-left: auto;
            .add-btn, .remove-btn{
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                cursor: pointer;
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
            .add-btn{
                margin-right: 12px;
                color: #0069a7;                
            }
            .remove-btn{
                color: #ed2c2b;
            }
        }
    }
    .banner-fields-box{
        margin-right:0;
        .form-label{
            margin-bottom: 7px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            color: #495057;
        }
        .form-control {
            height: 40px;
            border-color: #807879;
            border-radius: 3px;
            color: #3d3839;
            font-weight: 500;
            &::placeholder{
                color: #a1a1a1;
                opacity: 1;
            }
            &:-ms-input-placeholder{
                color: #a1a1a1;
            }
            &::-ms-input-placeholder{
                color: #a1a1a1;
            }
        }
    }
    .form-select{
        height: 40px;
        border-color: #807879;
        border-radius: 3px;
        color: #3d3839;
        font-weight: 500;
    }
}
.save-btn{
    margin-bottom: 20px;
    button{
        width: 73px;
        height: 35px;
        background: #0069a7;
        border: 0;
        cursor: pointer;
        outline: none;
        font-size: 14px;
        letter-spacing: 0;
        color: #fff;
        border-radius: 3px;
        transition: all 0.4s ease-in-out;
        &:hover{
            background: #004e7c;
        }
    }
}
.inventory-menu{
    color: white !important;
    background: #1d6aa8 !important;
    padding: 0px !important;
    a{
        padding: 0.625rem 0rem 0.625rem 3.5rem !important;
        color: #fff !important;
        height: 45px !important;
        &.active{
            background: #005d95 !important;
        }
        &:hover{
            background: #005d95 !important;
        }
    }
}
#sidebar-menu{
    &.sidebar-menu-style{
        .list-unstyled{
            li{
                .icon-wd, .icon-dash{
                    position: relative;
                    top: -3px;
                }
                a{
                    color: rgba(255, 255, 255, 0.5);
                    i{
                        opacity: 0.6;
                    }
                    &:hover{
                        i{
                            opacity: 1;
                        }
                    }
                }
                &.mm-active > a{
                    i{
                        opacity: 1;
                    }
                }
                ul{
                    &.sub-menu{
                        background: #242e38;
                        line-height: 30px;
                        letter-spacing: -0.04px;
                        padding-top: 10px;
                        padding-bottom: 12px;
                        li > a{
                            font-size: 12px;
                            padding-top: 0;
                            padding-bottom: 0;
                            position: relative;
                            &:before{
                                content: '\25CF';
                                font-size: 5px;
                                position: relative;
                                top: -3px;
                                margin-right: 7px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.form-outer-dropzone{
    position: relative;
}
.preview-container{
    .file-preview-container{
        .file-box{
            position: absolute;
            top: 0px;
            height: 100%;
            width: 100%;
            background: #fff;
        }
        .avatar-sm{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px;
        }
    }
    .cross-btn-upload{
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: white;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        text-align: center;
        font-weight: 500;
        color: red;
        cursor: pointer;
    }
}
.supply-resource-wrap{
    .sr-title-top{
        border-bottom: 1px solid #eceff5;
        padding-bottom: 33px;
        margin-bottom: 33px;
    }
}
.supply-customer-outer{
    border: 0 !important;
    .supply-customer-wrap{
        padding: 0;
        .upload-img-lines{
            margin-bottom: 24px;
        }
        .banner-fields-box{
            margin-bottom: 20px;
        }
    }
}
.featured-product-outer{
    margin-bottom: 20px !important;
    .featured-product-wrap{
        .selected-value{
            padding: 0;
            font-size: 11px;
            color: #645a5b;
            max-height: 100px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 8px;
              }
              
              &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: rgba(0,0,0,.5);
                -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
              }
            span{
                padding: 4px 22px 6px 7px;
                background: #ebebeb;
                border-radius: 3px;
                position: relative;
                display: inline-flex;
                margin-right: 11px;
                &:last-child{
                    margin-right: 0;
                }
                .cross-btn{
                    color: #645a5b;
                    position: absolute;
                    right: 6px;
                    top: 4px;
                    font-size: 11px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }
        .featured-inner-box{
            height: 188px;
            overflow: hidden;
            // overflow-y: auto;
            border: 1px solid #807879;
            border-radius: 3px;
            padding: 16px 0px 13px 12px;
            margin-top: 15px;
            @include scrolls9;
        }
    }
    .search-featured{
        max-width: 311px;
        height: 34px;
        margin-bottom: 15px;
        .form-control{            
            border-color: #807879;
            padding-right: 25px;
        }
        span{
            &.search-icon{
                position: absolute;
                top: 50%;
                right: 8px;
                font-size: 16px;
                transform: translateY(-50%);
                color: #645a5b;
            }
        }
    }
    .check-listing-container{
        overflow-y: scroll;
        height: 120px;
        .check-listing{
            .checkbox-line{
                margin-bottom: 4px;
            }
        }
    }
   
    .no-result{
        font-size: 12px;
    }
}
.top-categories-outer{
    margin-bottom: 20px !important;
    .home-banner-inner{
        margin-top: 0;
        .home-banner-left-box, .home-banner-right-box{
            h3{
                margin-bottom: 21px;
            }
            .form-select{
                margin-top: 4px;
            }
        }
    }
    .top-selling-drop-img{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 33px;
        .form-outer-dropzone{
            max-width: 146px;
            @media only screen and (max-width:767px){
                max-width: 100%;
            }
            .dropzone{
                .dz-message{
                    padding: 44px 10px;
                }
                h5{
                    line-height: 14px !important;
                }
            }
        }
        .preview-container{
            .file-preview-container{
                .file-box{
                    height: 100%;
                }
            }            
        }
        .upload-img-lines{
            align-self: end;
            margin-left: 15px;
            @media only screen and (max-width:767px){
                margin-left: 0;
            }
            ul{
                li{
                    margin-bottom: 8px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    span{
                        display: block;
                        @media only screen and (max-width:767px){
                            display: inline;
                        }
                    }
                }
            }            
        }
        @media only screen and (max-width:767px){
            flex-wrap: wrap;
            .top-selling-drop-left, .upload-img-lines{
                width: 100%;
            }
        }        
    }
}

.selected-value-products{

}