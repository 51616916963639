.fulfillment-container{
    margin: 20px var(--bs-gutter-x, 12px) !important;
    max-width: 98%;
    background: #fff;
    min-height: 620px;
    .card{
        margin-bottom: 30px;
    }
    .details-container{
        padding: 0px;
        .assignText{
            font-size: 14px;
            letter-spacing: 0px;
            margin-bottom: 6px;
        }
        &.step-2{
            .address-container{
                display: flex;
                .address-label{
                    margin-right: 20px;
                }
            }
        }
        .driver-table{
            border: solid 1px #655b5c;
            border-radius: 4px;
            td{
                font-size: 14px;
                color: #645a5b;
                border: none;
            }
        }
    }
    .fulfillment-row{
        padding: 30px;

        .steps{
            margin: 0px;
            .step{
                padding: 7.5px 20px;

                .desc{
                    font-size:14px;
                    font-weight: 500;

                    .pick-time{
                        color:  #645a5b;
                        font-size:10px;   
                        margin-bottom: 0px;                     
                    }
                }

                .tick{
                        color: #fff;
                        background: #0069a7;
                        height: 35px;
                        width:35px;
                        border-radius: 50%;
                        border:2px solid #0069a7;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;

                        i{
                            font-size: 24px
                        }
                }

                .number{
                    background-color: #fff;
                        color: #0069a7;
                        height: 35px;
                        width:35px;
                        border-radius: 50%;
                        border:2px solid #0069a7;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                        font-size:14px;
                }
                background-color: #f0f6fa;
                &.active{
                    background-color: #c2dceb;

                    .number{
                        background-color: #0069a7;
                        color: #fff;
                        height: 35px;
                        width:35px;
                        border-radius: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                        font-size:14px;
                    }
                }
            }
        }
    }
}

.disclamer{
    border-radius: 4px;
    border: solid 1px #fdeac9;
    background-color: #fef4e4;
    padding: 7.5px 15px;

    p{
        color:#b98a38;
        margin-bottom: 0px;
    }
}

table{
    tr{
        .quantity-td{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: unset;

            p{
                font-size: 14px;
            }
        }
        .part-no{
            color: #0069a7 !important;
            font-size: 14px;
            font-weight: 600;
        }
        .brand{
            color: #645a5b;
        }
        .quantity__input__container{
            width: 89px !important;
            position: relative;
            left: unset;
            transform: unset;
        }
        .products__plus, .products__minus{
            position: absolute;
            font-size: 11px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
        .quantity__input{
            width: 100%;
            text-align: center;
            font-weight: 500;
            opacity: 1;
            padding: 5px;
            background: transparent;
            border: 1px solid #645a5b;
        }
        .quantity__input::-webkit-outer-spin-button,
        .quantity__input::-webkit-inner-spin-button{
            -webkit-appearance: none;
            margin: 0;
        }
        .products__minus{
            left: 5px;
        }
        .products__plus{
            right: 5px;
        }
        .qty{
            color: #645a5b;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
        }
        .case{
            color: #898383
        }
    }
}

.step1-table{
    max-width: 75%
}

.btn-row{
    padding-left:30px;
    padding-right: 30px;

    .back-btn{
        background:  #655b5c !important;
        color: #fff;    
        border-color: #0069a7 !important;
    }
    
    .next-btn{
        background:  #0069a7 !important;
        color: #fff;
        border-color: #0069a7 !important  
    }
    
    #link-btn{
        color: #0069a7 !important;
        background-color: unset !important;
        border: unset !important;
        font-weight: 600;
    }
}

#link-driver{
    color: #0069a7 !important;
    background-color: unset !important;
    border: unset !important;
    font-weight: 500;
    outline: none;
    *:focus{
        border: none;
        outline: none !important;
    }
}

.driver-assign{
    padding-left: 22.5px !important;
    padding-right: 22.5px !important;

    .driver-table{
        border-radius: 4px !important;
        border: solid 1px #655b5c;

        tr{
            td{
                padding-top: 10px;
                padding-bottom: 10px;

                &:first-child{
                    padding-left: 15px;
                }

                &:last-child{
                    padding-right: 15px;
                    color:  #d54242;                 
                }
            }
        }
    }
}

.search{
    padding: 5px 7.5px;
    width: 100%;
    border-radius: 2px;
    border: solid 1px #d0cece;
}

i.bx-search{
    position: absolute;
    right: 20px;
    font-size: 17px;
    top: 18px;
}

.step4{
    border-radius: 4px;
  border: solid 1px #e1e3e5;

  .set-border{
    border-bottom: solid 1px #e1e3e5;
  }

  .step4-row{
      padding-left:30px;
      padding-right: 30px;
  }

  .step4-number{
      padding: 5px 15px 5px 0px;
      width: 40px;
      span{
        color: #d8d8d8;
        font-size: 48px;
      }
  }
  .step4-detail{
      padding-left: 15px;
      .heading{
          color: #3d3839;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 0px;
      }
      .sub-heading{
          margin-bottom: 0px;
          color: #645a5b
      }
  }
  .action{
      #link-btn{
        color: #0069a7 !important;
        background-color: unset !important;
        border: unset !important;
        font-weight: 600;
      }
  }
}
.blank{
    width: 30%;
}
.table-bordered{
    td,th{
        vertical-align: middle;
        p{
            margin: 0;
        }
    }
}

.assign-text{
    color:#51a90a;
    padding-right: 1.25rem;
    font-size:14px;
    font-weight: 500;

    i{
        color: #51a90a;
    }
}

.pick-time{
    color:  #645a5b;
    font-size:10px;   
    margin-bottom: 0px;  
    font-weight: 400;                   
}