.pricing-save-btn{
    background: #0069a7;
    border: none;
    color: white;
    border-radius: 5px;
}

.card-image-container{
    height: 145px;
    width: 150px;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    padding: 5px;
    img{
        width: 100%;
        height: 135px;
        object-fit: contain;
    }
}

.bx-arrow-back{
    cursor: pointer;
}

.card-images{
    display: flex;
    flex-direction: row;
    gap: 10px;
}