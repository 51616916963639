@import "../../assets/scss/theme.scss";

table{
    tbody{
        background-color: #fff !important;
    }
}

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }
