.products-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.products-heading{
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: #20253a;
}
.products-nav-btn{
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 500;
    color: #645a5b;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    border: solid 1px #d0cece;
    background-color: var(--white);
}
.add--products--btn{
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #d54242;
    color:#fff;
}
.products-export-btn{
    margin: 0 11px;
}
.navItems{
    cursor: pointer;
}
@media only screen and (max-width:768px){
    .products-nav{
        row-gap: 10px;
        flex-direction:column;
        align-items:baseline
    }
}

#tooltip-scrollBar{
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
    
      &::-webkit-scrollbar-track {
        background: #f3f0f1;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #3d3839;
        border-radius: 10px;
        border: 3px solid #f3f0f1;
      }
}