.staff-management-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}
.staff-management-main  .staff-management-heading{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal; 
    line-height: 1.2; 
    letter-spacing: normal; 
    color: #20253a;
}
.staff-management-main .add-btn{
    width: 146px;
    height: 35px;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #d54242;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color:white;
    border: none;
    &:hover{
        background-color: #cb1818;
    }
}
.staff-management-thead{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal; 
    line-height: 0.77;
    letter-spacing: normal;
    color: #495057;
}
.staff-management-tbody{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal; 
    font-style: normal;
    line-height: 0.77; 
    letter-spacing: normal;
    color: #495057;
}
.main-add-status{
    display: flex;
    align-items: center;
}
.status-check-input{
    cursor: pointer;
}
.form-continue-btn{
    &:hover{
        background-color: #d54242;
    }
}

// STAFF INFORMATION

.information-edit{
    cursor: pointer;
    &:hover{
        color: #d54242;
    }
}
.staff-information-main{
    width: 70%;
    margin: 0 auto;
}
.staff-information-div{
    display: flex;
    // justify-content: space-between;
}
.information--div{
    width: 50%;
}
.staff-information-add{
    font-family: Montserrat;
    font-size: 14px; 
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
   color: #645a5b;
   margin-right: 24px;
  }
.staff-status{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: normal;
    color: #807879;
    margin-right: 12px;
}
.form-check-input{
    background-color: transparent !important;
    &:checked {
      background-color: #0069a7 !important;
    }
}
.status-active{
    color: #3d3839;
}
.staff-main-name{
    font-family: Montserrat;
    font-size: 18px; 
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #0069a7;
}
.staff-manafement-light{
    font-family: Montserrat;
    font-size: 13px;
   font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;  
    letter-spacing: normal;
    color: #655b5c;

}
.staff-manafement-dark{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #3d3839;
}
.staff-check-box{
    margin-right: 10px;
}
.staff-information-second-main{
    width: 70%;
    margin: 0 auto;

}



       // STAFF INFORMATION EDIT

 .staff-edit-main{
     display: flex;
     justify-content: space-between;
     margin-bottom: 15px;
 }     
 .edit-first-name{
     width: 48%;
 } 

 .tab-border{
     border-top:0px !important;
     border-left: 0px !important;
     border-right: 0px !important;
 }

 .edit-full-wt{
     width:100%;
 }
 input[checkbox]{
     cursor: pointer;
 }
 .staff-first-input{
     width: 100%;
     height: 40px;
     border-radius: 3px; 
     border: solid 1px #c4c4c4;  
     background-color: var(--white);
     font-family: Montserrat;
     padding-left: 20px;
     font-size: 13px;
     font-weight: 700; 
     font-stretch: normal;
     font-style: normal;
     line-height: 2;
     letter-spacing: normal;
     color: #3d3839;
 }
 .staff-phn-no{
    width: 69px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #c4c4c4;
    background-color: var(--white);
    font-size: 13px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 2; 
    letter-spacing: normal;
    color: #3d3839;
    padding-left: 20px;
  }

 .staff-minus{
     margin: 0 10px;
     font-size: 13px;
     font-weight: 700;
     font-stretch: normal; 
     font-style: normal;  
     line-height: normal;  
     letter-spacing: -2px;  
     color: #3d3839;
 }
 .staff-save-changes{
    width: 132px;
    height: 40px;
    border-radius: 3px;
    background-color: #d54242;
    border: none;
 }
 .staff-cancel-btn{
    width: 83px;
    height: 40px;
    border-radius: 3px;
    background-color: #645a5b;
    border: none;
    margin-left: 15px;
    &:hover{
        opacity: 0.8;
    }
 }
 .staff-btn-clr{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal; 
    font-style: normal;  
    line-height: normal;  
    letter-spacing: normal;  
    text-align: center;  
    color: white;
    &:hover{
       opacity: 0.9;
    }
 }
 .card-main-body{
     padding: 46px;
 }
 .save-and-approv-div{
     display: flex;
 }

        //  ADD STAFF

.Add-staff{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #20253a;
}
.verified-icon{
    padding-bottom: 3px;
    margin: 0 5px;
}
.status-active__div{
    display: flex;
}
.customer-notes-input{
    textarea{
        resize: none;
        overflow: hidden;
        min-height: 30px;
    }
}

.customer-notes-save-btn{
    border:1px solid #d54242;
    color: #d54242;
    font-weight: 500;
    padding: 7px 20px;
    border-radius: 5px;
    background: none;
    &:hover{
        background-color: #cb1818;
        color:white;
    }

}

.customer-notes-main-div{
    display: flex;
    align-items: center;
    margin: 20px;
}
.customer-notes-div{
    margin: 0 20px;
}
@media only screen and (max-width:780px) {
    .staff-edit-main{
        flex-direction: column;
    }
    .edit-first-name{
        width: 100%;
        margin-bottom: 3px;
    }
    .active-customer-upper-card{
        flex-direction: column;
        div{
            border-right: none;
            border-bottom: solid 1px #979797;
            width: 70%;
            margin: 0 auto;
            padding-bottom: 5px;
        }
    }
    .customer-notes-div{
        flex-direction: column;
        
    }
    .customer-notes-label{
        // text-align: left;
    }
    .profile-common{
        flex-direction: column;
    }
    .customer-notes-input{
        width: 100%;
    }
    .customer-notes-main-div{
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start;
    }
   #customer-notes-text{
       width: 100%;
       display: flex;
   }
   .assign-wd-button {
    margin-top: 10px;
  }
  .staff-phn-no{
      width: 20% !important;
  }
  .staff-phn-landline{
    width: calc(80% - 16px) !important
  }
  .staff-minus{
      margin: 0px ;
  }

}
@media only screen and (max-width:990px) {
    .phn-email{
        margin-top: 8px;
    }
    .alternate-heading{
        margin-top: 20px;
    }
}
@media only screen and (max-width:676px) {
    .staff-management-main{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .main-add-status{
        margin-top: 12px;
    }
    .staff-information-main{
        width: 100%;
    }
    .staff-information-second-main{
        width: 100%;
    }
    .wd-business-nav{
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
    .all-marked-wd{
        margin: 5px 0;
    }
} 
@media only screen and (max-width:480px) {
    .main-add-status{
        flex-direction: column;
        align-items: flex-start;
    }
    .status-active__div{
        margin-top: 10px;
    }
    .staff__phone{
        display: block;
        width: 100% !important;
        margin-top: 10px;
    }
    
    .warehouse--staff{
        flex-direction: column;
        align-items: flex-start;
    }
    .drop-staf{
        margin-left: 0;
        margin-top: 8px;
    }
    .information--div{
        width: 100%;
    }
    
}
@media only screen and (max-width:568px){
    .staff-information-div{
        flex-direction: column
    }
}
@media only screen and (max-width:369px) {
    .staff-btn-clr{
        margin: 0px;
        margin-top: 10px;
        display: block;
    }
}
@media only screen and (max-width:480px) {
    .save-and-approv-div{
        flex-direction: column;
    }
    .new-wd-approval-btn{
        margin-right: 0px !important;
        width: 100%;
    }
}