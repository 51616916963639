.list-tooltip{
    display: flex;
    flex-direction: column;
    justify-content: center;

    i{
        font-size:10px
    }
}

.product-list{
    display: block;
    max-height: 200px;
    overflow-y: scroll;

    .product-number{
        padding: 5px 10px;
        font-size: 14px;
    }

}

.info-icon{
    .tooltiptext1 {
        visibility: hidden;
        width: 150px;
        height: fit-content;
        padding: 11.8px 13px 18px 14px;
        margin: 5px;
        border-radius: 4px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.19);
        background-color: #fff !important;
        color: #645a5b;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 11px;
        line-height: 1.36;
        letter-spacing: normal;
        color: #645a5b;
      }
      .arrow::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 40%;
        margin-left: -5px;
        border-width: 9px;
        border-style: solid;
        border-color: rgb(240, 236, 236) transparent transparent transparent;
        box-shadow: 0 0 5px 0.9 rgba(0, 0, 0, 0.5);
      }
      .tooltipdata{
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
    
        .part--id{
            color: #645a5b;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: .5px;
        }
      }
      .tooltip-innerdata{
        align-items: stretch;
        display: flex;
        flex-direction: column;
        max-height: 200px;
        overflow-y: scroll;
      }
      .partid-qty-div{
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
      }
      .partid-qty-div span{
        color: #3d3839;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: .4px;
      }
}
  .info-icon:hover .tooltiptext1 {
    visibility: visible;
    opacity: 1;
  }

.order-table{
  .table-responsive{
    table{
      tbody{
        tr{
          border-bottom: 1px solid #f8f9fa;
  
          td:last-child{
            // position: absolute;
            margin-left: -15px;
          }
        }
      }
    }
  }
}