.report-download{
    background: #006aa8;
    color: #fff;
    padding: 5px 15px;
    border: unset;
    border-radius: 2px;

    i{
        color: #fff;
        font-size: 17px;
        margin-right: 5px;
    }
}

.date-selection{
    width: 100%;
    padding: 10px;
    border-color: #e0e0e0;
}