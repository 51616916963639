.invoicing-download-btn {
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #0069a7;
    font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  padding: 8px 20px 9px 21px;
  border: none;
}
.select__wd{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.85;
    color: #655b5c;
  }
  .select--wd--drop{
    width: 255px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #c4c4c4;
  background-color: #fff;
  margin-left: 10px;
  font-size: 13px;
  line-height: 2;
  color: #3d3839;
  padding-left: 13px;
  }
  @media only screen and (max-width: 426px) {
    .select--wd--drop{
      margin-left: 0px;
    }
  }
  .year-dropdown{
    width: 188px;
  height: 40px;
  padding: 7px 15px;
  border-radius: 3px;
  border: solid 1px #c4c4c4;
  background-color: #fff;
  font-size: 13px;
  line-height: 2;
  color: #3d3839;
}

.invoicing-icon-container{
  background-color: #0069a7;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .invoicing-icon{
    font-size: 25px;
    color: white;
  }
}

.invoice-text{
  color: #0069a7;
}
.modal--width .modal-content{
  width: 700px !important;
}
.modal--width {
  width: 700px !important;
}
.heading--div{
  padding-left: 0px !important;
}