.download-csv-heading{
    color: #0069a7;
}

.bulk-upload-btn{
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 500;
    color: #645a5b;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    border: solid 1px #d0cece;
    background-color: var(--white);
}

.bulk-upload-modal-container{
    .cancel-btn{
        background-color: #655b5c;
        border: none;
        padding: 8px 20px;
        color: white;
        border-radius: 5px;
    }
    .upload-btn{
        background-color: #0069a7;
        border: none;
        padding: 8px 20px;
        color: white;
        border-radius: 5px;
        opacity: 0.3;
    }
    .upload-btn-active{
        opacity: 1;
    }
    .replace-btn{
        border: 1px solid grey;
        padding: 3px 10px;
        border-radius: 5px;
        border-color: none;
    }
    .bx-file{
        font-size: 18px;
    }
    .error-warning-container{
        background-color: #fde1e1;
        padding: 10px;
        font-size: 12px;
        .bxs-info-circle{
            font-size: 18px;
            color: #d54242;
        }
    }
}