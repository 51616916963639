.set-password{
    
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #645a5b;
}
.please-set-password{
    
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    color: #645a5b;
}
.new-password{
    
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.29px;
    color: #645a5b;
}
.set-password-btn{
    
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    background-color: #d54242;
    border: none;
    color: white;
}
.set-password-btn:hover{
    color: white;
}
.lower-privacy{
  
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0069a7;
  margin-top: 20px;
}
.lower-copyright{
    
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #645a5b;
}
.login-here{
    width: 164px;
    height: 45px;
    background-color: #d54242;
    border: none;
    
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color:white;
}
.green-tick{
  display: flex;
  justify-content: center;
}
.input-div-set-password{
  position: relative;
}
.eye-icons-password{
  position: absolute;
  top:10px;
  right: 5%;
}
.eye-icon-confirm-password {
  position: absolute;
  right: 5%;
  top:10px;

}
@media only screen and (max-width:480px) {
  .eye-icon-confirm-password, .eye-icons-password {
    right: 5%;
  }
}
.password-main{
  position: relative;
}
.eye-password{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.new-password {
  width: 100%;
  padding: 0.47rem 0.75rem;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.was-validated .form-control:invalid, .form-control.is-invalid{
  background-image: none!important;
}