.main-register-box {
    margin-top: 30px;
    @media screen and (max-width: 767px) {
      margin-top: 15px;
    }
  }
  .mainn {
    display: flex;
    max-width: 850px;
    min-width: 330px;
    margin-bottom: 40;
  
    // max-width: 1900px;
    // margin: 0 auto;
  
    // justify-content: center;
    // background-color: #f7f8fa;
    margin: 15px auto 0;
  }
  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    .mainn {
      max-width: 700px;
    }
  }
  @media only screen and (max-width: 767px) {
    .mainn {
      max-width: 95%;
      margin-bottom: 25px;
    }
  }
  
  .input-select-container {
   
    position: relative;
    width: 374px;
    height: 37px;
    border: solid 1px #807879;
    background-color: white;
    margin-top: 6.5px;
    border-radius: 3px;
    .arrow-icon{
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      color: #888081;
      width: 10px;
    }
    .input-select {
      opacity: 1;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      color: #645a5b;
      padding-left: 10px;
      &:focus {
        border: none !important;
        outline: none;
      }
    }
    .dropdown--list {
      border: solid 1px #807879;
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      position: absolute;
      left: -1px;
      width: 100.5%;
      background-color: white;
      z-index: 1;
      box-shadow: 0 8px 12px -1px rgba(0, 0, 0, 0.07);
      max-height: 195px;
      overflow-y: scroll;
      hr{
        color: #e5e3e3;
        width: 340px;
        margin: 0;
      }
      .add-new-address {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #ed2c2b;
        padding: 12px 0;
        cursor: pointer;
      }
      .country-names-list{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #645a5b;
        padding: 12px 0;
        // height: 50px;
        cursor: pointer;
        .country-street-name{  font-family: Montserrat;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.17px;
          color: #645a5b;}
      }
    }
  
  }
  
  .mainn-div {
    width: 100%;
    background-color: #fff;
    // height: auto;
    padding: 20px 40px;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      padding: 20px;
      margin-left: 4px;
    }
    // @media only screen and (max-width:1024px) {
    //     width: 94%;
    // }
  
    .mainn-div {
      width: 100%;
      background-color: #fff;
      // height: auto;
      padding: 20px 40px;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      @media only screen and (max-width: 480px) {
        width: 91%;
      }
      // @media only screen and (max-width:1024px) {
      //     width: 94%;
      // }
    }
  
    .contact-link {
      color: #0069a7;
    }
    .ql {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 35px;
      align-items: center;
    }
    .create-account {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      color: #645a5b;
      display: none;
      @media only screen and (max-width: 767px) {
        display: block;
        font-size: 19px;
        margin-top: 4px;
      }
    }
    .apply_div {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      color: #645a5b;
      margin-top: 2px;
    }
    .step__btn {
      width: 128px;
      height: 38px;
      background-color: #f5f5f5;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: center;
      color: #ed2c2b;
      border: none;
      @media only screen and (max-width: 480px) {
        width: 100px;
        width: 100px;
        height: 26px;
        font-family: Montserrat;
        font-size: 16px;
      }
    }
    .second {
      color: #b1acac;
    }
    .business__row {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .busines__div {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #0069a7;
    }
    .all__marked {
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.08;
      letter-spacing: normal;
      text-align: right;
      color: #645a5b;
    }
    .company--row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    // .company-name-div {
    //   width: 49%;
    // }
  
    .label {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      color: #645a5b;
      margin-bottom: 2px;
    }
    .company_input {
      width: 374px;
      height: 45px;
      border: solid 1px #807879;
      background-color: #fff;
      opacity: 0.77;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #645a5b;
      padding-left: 10px;
      @media only screen and (max-width: 1024px) {
        width: 301px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      @media only screen and (max-width: 480px) {
        width: 100%;
        height: 45px;
        border: solid 1px #807879;
        background-color: #fff;
        opacity: 0.77;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #645a5b;
        padding-left: 10px;
        @media only screen and (max-width: 780px) {
          width: 100%;
        }
        @media only screen and (max-width: 480px) {
          width: 100%;
        }
      }
      input[type="text"]:focus {
        outline: none;
        // border: 1px solid #ed2c2b;
      }
    }
    input[type="text"]:focus {
      outline: none;
      // border: 1px solid #ed2c2b;
    }
    // .company_input:focus{
    //    border: solid 1px #ed2c2b;
    // }
    // @media only screen and (max-width:767px) {
    //     .create-account{display: block; font-size: 19px;margin-top: 2px; }
    //     .apply_div{display: none;}
    //     .all__marked{display: none;}
    //     .company--row{display: flex; flex-direction: column;}
    //
  
    //     .company-name-div{width: 99%;}
    //     .mobile-main-div{width: 99%;}
    // }
    @media only screen and (max-width: 480px) {
      // .company--row{display: flex; flex-direction: column;}
      // .mobile--row{display: flex; flex-direction: column;}
      // .checkbox--box{display: flex; flex-direction: column;}
      // .resale--row{display: flex; flex-direction: column;}
      .upload--div {
        margin-top: 10px;
      }
    }
    .mobile--row {
      display: flex;
    }
    //   .resale--row {
    //     display: flex;
    //     flex-direction: column;
    //   }
    .upload--div {
      margin-top: 10px;
    }
  }
  .mobile-main-div {
    width: 32%;
  }
  .mobile--input {
    width: 242px;
    height: 45px;
    border: solid 1px #807879;
    background-color: #fff;
    opacity: 0.77;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #645a5b;
    padding-left: 10px;
    @media only screen and (max-width: 1024px) {
      width: 195px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .chain__affiliation {
    margin: 0 20px;
    @media only screen and (max-width: 1024px) {
      margin: 0 10px;
    }
  }
  .checkbox--box {
    @media only screen and (max-width: 1024px) {
      width: 90%;
    }
  }
  .checkbox--box {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    margin-bottom: 20px;
    width: 88%;
    @media only screen and (max-width: 780px) {
      width: 90%;
    }
  }
  .check__box__gap {
    margin-bottom: 7px;
  }
  .checkbox--title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #645a5b;
    @media only screen and (max-width: 480px) {
      width: 99%;
    }
  }
  .checkbox-name {
    font-family: Montserrat;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    //line-height: 1.86;
    letter-spacing: normal;
    color: #645a5b;
    align-items: center;
    display: inline-block;
    // vertical-align: text-top;
    // margin-left: 10px;
    // padding-top: 2px;
    @media only screen and (max-width: 780px) and (min-width: 480px) {
      font-size: 11px;
    }
  }
  .check---box {
    width: 15px;
    height: 15px;
    border: solid 1px #645a5b;
    // background-color: red;
  }
  
  .check-box-main {
    // margin-top: -3px;
    margin-bottom: 0!important;
    margin-left: 7px;
    display: block;
    @media only screen and (max-width: 768px) and (min-width: 481px) {
      margin-top: -1px;
    }
  }
  // .other--input--row {
  //   display: flex;
  //   width: 92%;
  //   flex-direction: row-reverse;
  //   margin-top: -4.4em;
  //   @media only screen and (max-width: 480px) {
  //     margin-top: -2.4em;
  //   }
  // }
  .other--input--row {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    margin-top: -0.4em;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 0em;
      margin-left: 15px;
    }
    @media only screen and (max-width: 480px) {
      margin-top: -0.4em;
    }
  }
  .other--input {
    border: none;
    border-bottom: 1px solid #645a5b;
    width: 241px;
    padding-bottom: 5px;
    padding-left: 5px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    //    line-height: 1.43;
    color: #645a5b;
    outline: none;
    // float: right;
    @media only screen and (max-width: 1024px) and (min-width: 767px) {
      width: 226px;
      font-size: 12px;
    }
  }
  .other--input:focus {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: 1px solid #645a5b;
  }
  .tax--div {
    margin-top: 25px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #0069a7;
  }
  @media only screen and(max-width:767px) and (min-width: 481px) {
    .tax--div {
      margin-top: 46px;
    }
  }
  @media only screen and(max-width:480px) {
    .tax--div {
      margin-top: 0px;
    }
  }
  .resale--row {
    display: flex;
    // justify-content: space-between;
    // width: 66%;
    margin-top: 12px;
    @media only screen and (max-width: 780px) {
      width: 80%;
    }
    @media only screen and (max-width: 780px) {
      width: 100%;
    }
  }
  .resale-tax-div {
    margin-right: 30px;
  }
  .resale--div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #645a5b;
    margin-bottom: 3px;
  }
  .optional {
    font-size: 12px;
    font-weight: 500;
  }
  .resale--input {
    width: 250px;
    height: 45px;
    border: solid 1px #807879;
    background-color: #fff;
    opacity: 0.77;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #645a5b;
    margin-top: 5px;
    padding-left: 10px;
    @media only screen and (max-width: 780px) {
      width: 200px;
    }
    @media only screen and (max-width: 780px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    .resale--row {
      width: 100%;
    }
    .resale-tax-div {
      width: 38%;
    }
    .upload__div {
      width: 64%;
    }
  }
  @media only screen and (max-width: 767px) {
    .create-account {
      display: block;
      font-size: 19px;
      margin-top: 2px;
    }
    .apply_div {
      display: none;
    }
    .all__marked {
      display: none;
    }
    .company--row {
      display: flex;
      flex-direction: column;
    }
    .mobile--row {
      display: flex;
      flex-direction: column;
    }
    .company-name-div {
      width: 99%;
    }
    .mobile-main-div {
      width: 99%;
    }
    .checkbox--box {
      display: flex;
      flex-direction: column;
    }
    .resale--row {
      display: flex;
      flex-direction: column;
    }
    .upload--div {
      width: 99%;
    }
    .chain__affiliation {
      margin: 0 0;
    }
    .mobile--input {
      width: 100%;
    }
  }
  .pdf-input {
    margin-left: 8px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #645a5b;
  }
  .choose--btn {
    width: 158px;
    height: 37px;
    border-radius: 3px;
    border: solid 1px #d3d9e3;
    background-color: #f2f3f5;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    // margin-top: 5px;
    text-align: center;
    color: #3d3839;
    border: none;
  }
  .choose--btn:hover {
    outline: none;
    background-color:#888081  !important;
  }
  .continue--div {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
  .continue--btn {
    width: 326px;
    height: 50px;
    background-color: #ed2c2b;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    border: none;
    margin-bottom: 20px;
  }
  .continue--btn:focus {
    outline: none;
  }
  
  //step 2
  .number--location {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #645a5b;
    //margin-top: 15px;
    margin-bottom: 3px;
  }
  .location--dropdown {
    display: flex;
    flex-direction: column;
  }
  .select-dropdown {
    width: 374px;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px #807879;
    background-color: #fff;
    opacity: 0.77;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: #3d3839;
    @media only screen and (max-width: 780px) {
      width: 280px;
    }
    @media only screen and (max-width: 780px) {
      width: 100%;
    }
  }
  .back-icon {
    margin-right: 13px;
    @media only screen and (max-width: 480px) {
      margin-right: 0px;
      margin-bottom: -2px;
      margin-right: 5px;
    }
  }
  .create-your-heading {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .select-dropdown:hover {
    outline: #807879;
  }
  .info-icon {
    position: relative;
    display: inline-block;
    margin-left: 3px;
  }
  .tooltiptextt {
    visibility: hidden;
    width: 150px;
    height: fit-content;
    //  margin: 28.2px 16px 3px 130px;
    padding: 11.8px 13px 18px 14px;
    margin: 5px;
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff;
    color: #645a5b;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  
    font-family: Montserrat;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #645a5b;
  }
  .tooltiptextt::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 40%;
    margin-left: -5px;
    border-width: 9px;
    border-style: solid;
    border-color: rgb(240, 236, 236) transparent transparent transparent;
    box-shadow: 0 0 5px 0.9 rgba(0, 0, 0, 0.5);
  }
  .info-icon:hover .tooltiptextt {
    visibility: visible;
    opacity: 1;
  }
  .location-dropdown {
    width: 374px;
    height: 45px;
    border: solid 1px #807879;
    background-color: #fff;
    opacity: 0.77;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    padding-left: 10px;
    color: #3d3839;
    margin-bottom: 13px;
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .location--row {
    display: flex;
    justify-content: space-between;
    padding: 19px 15px 19px 0;
    // border-top: solid 1px #d0cece;;
  }
  .location {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #0069a7;
  }
  .newlocation {
    color: #3d3839;
  }
  .first-location {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: normal;
    color: #9d9a9b;
    margin-left: 15px;
  }
  .main-state {
    margin: 10px 0;
  }
  .location-title-div {
    margin-top: 7px;
    margin-bottom: 20px;
  }
  .location-name-input {
    width: 100%;
    height: 45px;
    border: solid 1px #807879;
    background-color: #fff;
    padding-left: 10px;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #3d3839;
  }
  .billing-address-check {
    @media only screen and (max-width: 780px) {
      display: flex;
    }
    @media only screen and (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
  }
  .flex-checkbox {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 780px) {
      // background-color:chartreuse;
      font-size: 10px;
      // display: none;
      // width: 220px;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
  .sameasshipping-checkbox {
    // margin-top: 2px;
    @media only screen and (max-width: 480) {
      background-color: #ed2c2b;
    }
  }
  .same-ship-address {
    @media only screen and (max-width: 1024px) and (min-width: 768px) {
      display: none;
    }
  }
  .shiping--row {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
  }
  .new-same-address {
    display: none;
    margin-left: 20em;
    @media only screen and (max-width: 1024px) and (min-width: 768px) {
      display: block;
    }
  }
  .shipping-div {
    margin-bottom: 12px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #3d3839;
    @media only screen and (max-width: 780px) and (min-width: 480px) {
      font-size: 12px;
    }
  }
  .first-name-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .city--div {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
  }
  .city--row {
    width: 180px;
    height: 45px;
    border: solid 1px #807879;
    background-color: #fff;
    padding-left: 10px;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #3d3839;
    @media only screen and (max-width: 780px) {
      width: 130px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .city--input {
    width: 180px;
    @media only screen and (max-width: 1024px) {
      width: 180px;
    }
    @media only screen and (max-width: 780px) {
      width: 130px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .billing {
    @media only screen and (max-width: 480px) {
      margin-top: 12px;
    }
  }
  .primary-location {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .check {
    margin-right: 7px;
  }
  .billing---div {
    @media only screen and (max-width: 480px) {
      // margin-bottom: 10px;
    }
  }
  .set-primary {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #645a5b;
  }
  .set-primart-location {
    // margin-bottom: 25px;
    display: flex;
    align-items: center;
  }
  .information-contact {
    margin-bottom: 15px;
  }
  .done--btn {
    width: 103px;
    height: 41px;
    background-color: #645a5b;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    border: none;
    &:hover{
      opacity: 0.9;
    }
  }
  .done--line {
    margin-top: 40px;
  }
  .please-contact-qls {
    height: 50px;
    background-color: #f6dee0;
    font-family: Montserrat;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #3d3839;
    padding-left: 20px;
    padding-right: 125px;
    padding-top: 5px;
    margin-top: 4px;
    
    @media only screen and (max-width: 480px) {
      padding-right: 0;
      height: 70px;
      padding: 4px 13px 4px 13px;
      font-size: 12px;
    }
  }
  .please-contact-qls-note {
    font-family: 500;
  }
  .add-more-flex {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 6px;
  }
  .add-more {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: right;
    color: #ed2c2b;
    // float: right;
  }
  .plus--icon {
    margin-bottom: 5px;
  }
  .addnew-address {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ed2c2b;
  }
  .contact--div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }
    @media only screen and (max-width: 480px) {
      display: flex;
      justify-content: space-between;
    }
  }
  #contact-information {
    // margin-bottom: 30px;
    // margin-top: 30px;
  }
  .owner-primary {
    margin-bottom: 17px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #3d3839;
  }
  // .owner-primary-div{
  //     width:48%
  // }
  // .main-phone{
  //     margin: 10px 0;
  // }
  // .main-email{
  //     margin-top: 10px;
  // }
  .condition-row {
    margin-top: 18px;
  }
  .term-condition {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #645a5b;
    @media only screen and (max-width: 780px) {
      font-size: 12.1px;
    }
    // @media only screen and (max-width:400px) {
    //     // width: 285px;
    //     margin-left: 20px;
    //     margin-top: -22px;
    // }
    // @media only screen and (max-width:450px) {
    //     // width: 285px;
    //     margin-left: 20px;
    //     margin-top: -28px;
    // }
  }
  .term-span {
    color: #ed2c2b;
  }
  .register--row {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .register--btn {
    width: 326px;
    height: 50px;
    background-color: #ed2c2b;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    border: none;
  }
  .register--btn:hover {
    outline: none;
    background-color: #ec0606;
  }
  .hover-del-icon{
    padding: 4px 4px 10px 4px;
    &:hover{
      background-color:#807879 ;
    }
  }
  .neww {
    display: flex;
    margin: 0 40px;
    max-width: 1900px;
  }
  @media only screen and (max-width: 480px) {
    .neww {
      flex-direction: column;
    }
  }
  .new {
    border: solid 1px var(--purple-grey);
    background-color: var(--white);
    width: 374px;
    height: 45px;
    opacity: 0.77;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #645a5b;
    //   margin: 10px;
  }
  @media only screen and (max-width: 480px) {
    .new {
      width: 100%;
    }
  }
  .qq {
    margin-left: 10px;
  }
  .ww {
    margin-right: 10px;
  }
  .regis-new {
    width: 59.02%;
    height: 1000px;
    border-radius: 5px;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.02);
    border: solid 1px rgba(227, 227, 227, 0.57);
    background-color: var(--white);
    margin: 0 auto;
  }
  .owner--first--div {
    width: 49%;
  }
  
  .contact-heading {
    margin-top: 10px;
    margin-bottom: 17px;
  }
  
  .first--input--div {
    @media only screen and(max-width:1024px) {
      width: 147px;
    }
  }
  @media only screen and (max-width: 767px) {
    .owner--first--div {
      width: 98%;
    }
    .city--div {
      flex-direction: column;
    }
    .first--input--div {
      width: 100%;
    }
    .shiping--row {
      flex-direction: column;
    }
  }
  .need---help {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    text-align: right;
    color: #645a5b;
    text-align: center;
    display: none;
    @media only screen and(max-width:767px) {
      display: block;
    }
  }
  .delete--icon {
    margin-right: 38px;
    padding: 4px 4px 10px 4px;
    &:hover{
      background-color: #e7e7e7;;
    }
  }
  .hover-plus-icon{
    padding: 4px 5px 4px 5px;
    &:hover{
      background-color: #e7e7e7;
    }
  }
  .pdf-upload {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 480px) {
    .mainn-div .ql {
      margin-bottom: 20px;
    }
    .btn-line {
      margin-top: 25px;
      margin-bottom: 14px;
    }
    .location-title-div {
      margin-top: 14px;
      margin-bottom: 10px;
    }
    .delete--icon {
      margin-right: 15px;
    }
    .condition-row {
      margin-top: 10px;
    }
    .register--row {
      margin: 16px 0 25px 0;
    }
    .pdf-upload {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .pdf-row {
      margin-top: 10px;
    }
  }
  
  @media only screen and (max-width:768px) {
    .input-select-container{ 
      width: 100%;
    hr{
      width: 100%!important;
    }
  }  
  }
  .account-div{
      margin-top: 18px;
  }
  .mobile--row-wd{
      margin-top: 12px;
  }
  .main-email{
      margin: 10px 0;
  }
  .main--title{
      margin-top: 10px;
  }
  .file-uplaod-btn{
    width: 158px;
    height: 45px;
    border-radius: 3px;
    border: solid 1px #d3d9e3;
    background-color: #f2f3f5;
  }
  .main--wd-hr{
      margin: 0 !important;
  }
  .wd-done-div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

  }
  .wd-done-btn{
    width: 103px;
    height: 41px;
    background-color: #645a5b;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px; 
    text-align: center;
    color: white;
  }
  .wd-add-location{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: right;
    margin-top: 5px;
    color: #ed2c2b;
    margin-bottom: 15px;
  }
  .upload-wd{
      height: 45px;
      
  }
  .wd-add-icon{
      font-size: 12px;
      margin-right: 6px;
  }
  .wd-location-div{
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      margin-bottom: 22px;
  }
  .wd-warehouse-location{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #3d3839;
  }
  .upload-tax{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #645a5b;
  }
  .main-affilication{
    position: relative;
  }
  .new-down-arrow{
    position: absolute;
    bottom: 18px;
    right: 15px;
  }
  @media only screen and (max-width:'992px') {
    .mobile-resale-div{
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }