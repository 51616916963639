.add-new-customer-btn {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  background-color: #d54242 !important;
  font-size: 14px;
  color: white;
  padding: 8px 10px;
  border: none;
  &:hover {
    background-color: #b63a3a !important;
  }
}
.export-btn {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  border: solid 1px #d0cece;
  background-color: white;
  padding: 8px 10px;
  font-size: 14px;
}
.stop-icon {
  color: #807879;
  font-size: 20px;
}
.eye-icon {
  color: #807879;
  font-size: 20px;
  margin-right: 5px;
}
.active-div {
  border-radius: 11px;
  border: solid 1px #ceebbb;
  background-color: #def2d0;
  text-align: center;
  padding: 0px 10px;
  width: fit-content;
  color: #508829;
  letter-spacing: -0.2px;
  font-size: 12px;
}
.pending-div {
  border-radius: 11px;
  border: solid 1px #fbe9c9;
  background-color: #fcf0db;
  text-align: center;
  padding: 0px 10px;
  width: fit-content;
  color: #916c2e;
  letter-spacing: -0.2px;
  font-size: 12px;
}
.rejected-div {
  border-radius: 11px;
  border: solid 1px #fcd2d2;
  background-color: #fde1e1;
  text-align: center;
  padding: 0px 10px;
  width: fit-content;
  color: #924040;
  letter-spacing: -0.2px;
  font-size: 12px;
}
.disabled-div {
  border-radius: 11px;
  border: solid 1px #f4f4f4;
  background-color: #f4f4f4;
  text-align: center;
  padding: 0px 10px;
  width: fit-content;
  color: #495057;
  letter-spacing: -0.2px;
  font-size: 12px;
}
.approved-div {
  border-radius: 11px;
  border: solid 1px #eadf97;
  background-color: #fbf2b8;
  text-align: center;
  padding: 0px 10px;
  width: fit-content;
  color: #7d6e06;
  letter-spacing: -0.2px;
  font-size: 12px;
}

.customer-container {
  .card-body {
    .nav-pills {
    }
    .nav-link {
      padding-bottom: 15px;
      color:#495057;

      &.active {
        color: #d54242;
        background: none;
        border-bottom: 2px solid #d54242;
        border-radius: 0;
      }
    }
  }
}

.dropdown {
  .customer-select {
    padding: 0px 15px;
    border-radius: 2px 0 0 2px !important;
    border: solid 1px #d0cece !important;
    background-color: #f9f9f9 !important;
    color: #767676 !important;
    height: 34px;
    // width: 150px;
    span {
      font-weight: 700;
    }
  }
  .status-dropdown{
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.status-dropdown-menu{
  width: 250px;
}

.customer-search-form {
  position: relative;
  width: 190px;
  height: 34px;

  .customer-input-border {
    border: solid 1px #d0cece;
    border-radius: 0 2px 2px 0;
    height: 34px;
  }
}
.active-customer-upper-card {
  div {
    flex: 1;
    text-align: center;
    border-right: solid 1px #979797;
    &:nth-last-child(1) {
      border: none;
    }
    p {
      font-size: 14px;
      line-height: 2.36;
      text-align: center;
      color: #645a5b;
    }
    span {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #3d3839;
    }
    .span-blue {
      font-size: 22px;
      font-weight: bold;
      color: #0069a7;
    }
    .para-blue {
      font-size: 12px;
      font-weight: 500;
      color: #0069a7;
      margin: 0;
    }
  }
}
.customer-notes-label {
  font-size: 14px !important;
  text-align: center;
  color: #3d3839 !important;
}

.dripicons-arrow-thin-left {
  font-size: 20px;
  font-weight: 700;
  padding-top: 6px;
}

.bottom-customer-card-container {
  .card-body {
    .nav-pills {
    }
    .nav-link {
      font-size: 14px;
      font-weight: 500;
      margin-right: 15px;
      padding-bottom: 15px;

      &.active {
        color: #d54242;
        background: none;
        border-bottom: 2px solid #d54242;
        border-radius: 0;
        font-weight: 500;
      }
    }
  }
  .add-new-btn {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.93;
    color: #0069a7;
    border: none;
    background: none;
    &:hover{
      color: #d54242;
    }
  }
  .bottom-customer-card-inner-location {
    position: relative;
    height: auto;
    width: 80%;
    margin: auto;
    &.customer-location {
      width: 90%;
    }

    .location-number {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.44;
      color: #3d3839;
    }
    .primary-location {
      margin-left: 10px;
      border-radius: 11px;
      border: solid 1px #eadf97;
      background-color: #fbf2b8;
      color: #7d6e06;
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 2px 5px;
    }

    .location-corporate {
      font-size: 12px;
      color: #ffffff;
      background-color: #3b82f6;
      border-radius: 11px;
      display: inline-block;
      margin-left: 10px;
      letter-spacing: -0.2px;
      padding: 2px 5px;
  }
  .location-customer {
    @extend .location-corporate;
    color: #ffffff;
    background-color: #f59e0b;
  }
    .bx,
    .mdi {
      font-size: 20px;
      margin-left: 10px;
    }
    .location-details-container {
      border-radius: 3px;
      margin: 10px 0;
      border: solid 1px #aad2fb;
      padding: 15px;
      .edit-btn {
        font-size: 14px;
        font-weight: 700;
        color: #0069a7;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .bxs-edit-alt {
        font-size: 16px;
        margin-right: 10px;
      }
      .location-container-inner {
        flex: 1;
        border-right: solid 1px #979797;
        &:last-child {
          border: none;
        }
      }
      .location-border{
        border-right: 0;
      }
      p {
        margin: 0;
      }
      .location-details-big-heading {
        font-size: 16px;
        line-height: 1.86;
        color: #3d3839;
        font-weight: bold;
      }
      .location-details-heading {
        font-size: 14px;
        line-height: 1.86;
        color: #3d3839;
        font-weight: bold;
      }
    }
  }
}

.location-modal-select-container {
  .location-modal-select {
    width: 100%;
    background: none !important;
    border: 1px solid #ced4da !important;
    text-align: left !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn-secondary {
    color: #3d3839 !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

.location-modal-cancel-btn {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  background-color: #645a5b;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  &:hover{
    opacity: 0.9;
  }
}
.location-modal-save-btn {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  background-color: #d54242;
  color: white;
  border: none;
  padding: 5px 10px;
  &:hover{
    background-color: #ed2c2b;
  }
}

.approve-btn {
  color: white;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  background-color: #367940;
  border: none;
  padding: 5px 20px;
  font-size: 14px;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover{
    opacity: 0.9;
  }
}

.reject-btn {
  color: white;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  background-color: #d54242;
  border: none;
  margin-right: 10px;
  padding: 5px 20px;
  font-size: 14px;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover{
    background-color: #ed2c2b;
  }
}

.location-modal-body {
  height: 85vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #353535;
    border-radius: 10px;
  }
}
.section-main-heading-blue {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  color: #0069a7;
  margin-bottom: 35px;
}

.bottom-customer-card-inner-profile {
  width: 70%;
  margin: auto;
  height: auto;
  label {
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
    line-height: 2;
    color: #968f90;
  }
  p {
    font-size: 13px;
    font-weight: 700;
    line-height: 2;
    color: #3d3839;
  }
  .heading-label {
    font-size: 14px;
    font-weight: 550;
    line-height: 1.86;
    color: #3d3839;
  }
  .section-inner-heading {
    font-size: 18px;
    font-weight: 550;
    line-height: 1.44;
    color: #0069a7;
    margin-bottom: 20px;
  }
  .form-check-input {
    margin-top: 5px;
    background-color: transparent !important;
    &:checked {
      background-color: #0069a7 !important;
    }
  }
}

.profile-edit-btn {
  color: #0069a7 !important;
  &:hover{
    color: #d54242 !important;
  }
  i {
    font-size: 18px !important;
    margin-right: 5px;
    
  }
  span {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.93;
  }
}

.upload-document-container {
  border-radius: 3px;
  border: solid 1px #d3d9e3;
  background-color: #f2f3f5;
  width: 158px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d3839;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  i {
    font-size: 24px;
  }
}

.profile-modal-heading-blue {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
  color: #0069a7;
  margin-bottom: 15px;
}
.profile-modal-content-heading {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.63;
  color: #3d3839;
  margin-bottom: 10px;
}

.rejected-modal-container {
  .reject-modal-heading-red {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.67;
    text-align: center;
    color: #d54242;
    margin-bottom: 20px;
  }
  .reject-modal-heading-black {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    color: #3d3839;
    margin-bottom: 5px;
  }
  textarea {
    width: 100%;
    height: 100px;
  }
  .reject-submit-btn {
    width: 100px;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #d54242;
    border: none;
    padding: 10px 0;
    font-size: 14px;
    color: white;
  }
  .reject-no-btn {
    width: 100px;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #645a5b;
    border: none;
    padding: 10px 0;
    font-size: 14px;
    color: white;
  }
}
.not-assigned-text {
  font-size: 14px;
  line-height: 1.43;
  color: #d54242;
}

.edit-assign-wd-text {
  font-size: 12px;
  line-height: 1.5;
  color: #645a5b;
}

.pending-text {
  font-size: 18px;
  font-weight: bold;
  color: #d54242 !important;
}
.active-text {
  font-size: 18px;
  font-weight: bold;
  color: green !important;
}
.verified-text {
  margin: 0px 20px;
  color: green;
  img {
    width: 20px;
  }
}

.resend-verification {
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
    background-color: #0069a7;
    color: white;
    height: 35px;
    border: none;
    padding: 0 15px;
    margin-left: 10px;
}

.copied-message {
  color: white;
  font-size: 12px;
  font-weight: 600;
  background: green;
  padding: 6px 12px;
  border-radius: 4px;
  position: absolute;
  top: 0;
}


.assign-wd-button {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(74, 19, 19, 0.05);
  background-color: #0069a7;
  color: white;
  height: 40px;
  border: none;
  padding: 0 25px;
  &:hover{
    opacity: 0.8;
  }
}

.delete-location-button {
  z-index: 2;
}

.location-info-row {
  z-index: 1;
}

.more-locations {
  color: #2c6b96;
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
}

.tooltip-location {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  .location-head {
    padding-top: 5px;
    font-size: 12px;
    color: #3d3839;
    font-weight: bold;
  }
  .location-desc {
    padding-bottom: 5px;
    color: #645a5b;
  }
  li {
    border-bottom: 1px solid #979797;
    &:last-child {
      border-bottom: none;
    }
  }
}

.register-input {
  display: block;
  width: 100%;
  padding: 0.55rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: solid 1px #807879;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.customer-nav{
  display: flex;
  justify-content: space-between;
}
.new--req-btn{
  background-color:  #d54242;
  margin-left: 15px;
  &:hover{
   opacity: 0.9;
  }
}
.modal-new-title{
  padding: 1rem 1.5rem !important;
}
.new-modal-body{
  padding: 0rem 1.5rem !important;
}

@media (max-width: 575.98px){
  .bottom-customer-card-inner-profile {
    width: 100%;
  }
  .bottom-customer-card-container {
    .bottom-customer-card-inner-location{
    .location-details-container {
    .location-container-inner {
      flex-direction: column;
      flex: 1;
      border-right: none;
      &:first-child{
        padding-left: 1.5rem;
      }
      }
  }}
}
.new--req-btn{
  margin: 0;
  margin-top: 5px;
}
.save-cancel-div{
  display: flex;
  flex-direction: column;
}
.new-wd-approval-btn {
  margin-right: 0px !important;
  margin-bottom: 10px;
}
}
@media only screen and (max-width:'780px'){
  .customer-profile-mobile{
    flex-direction: column;
  }
  .profile-para{
    margin-bottom: 2px !important;
  }
}

.approve-btn-disabled {
  opacity: 0.5;
  cursor: default!important;
}