// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }

  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}



// .tooltip .tooltip-arrow::before {
//   position: absolute!important;
//   border-color: white!important;
//   border-style: solid!important;
//   color: white!important;
//   background: white!important;
//   clip-path: polygon(0 1%, 0% 100%, 100% 50%);
 
// }
