.rdw-editor-toolbar{
    border: 1px solid #807879;
}
.rdw-editor-main{
    border: 1px solid #807879!important;
    overflow:hidden;
}


.rdw-colorpicker-wrapper,.rdw-fontfamily-dropdown,.rdw-block-dropdown{
    display: none;
}
.toolbarClassName{
    .rdw-inline-wrapper{
            .rdw-option-wrapper:last-child,
           .rdw-option-wrapper:nth-last-child(2),
           .rdw-option-wrapper:nth-last-child(3),
           .rdw-option-wrapper:nth-last-child(4){
           display: none;
       }
    }
    .rdw-text-align-wrapper,.rdw-history-wrapper,.rdw-embedded-wrapper,.rdw-emoji-wrapper,.rdw-image-wrapper{
        display: none;
    }
    .rdw-list-wrapper{
        .rdw-option-wrapper:last-child,
           .rdw-option-wrapper:nth-last-child(2){
               display: none;
           }
    }
}

.articleToolbar{
    .rdw-inline-wrapper{
        .rdw-option-wrapper:last-child,
       .rdw-option-wrapper:nth-last-child(2),
       .rdw-option-wrapper:nth-last-child(3),
       .rdw-option-wrapper:nth-last-child(4){
       display: none;
   }
}
.rdw-text-align-wrapper,.rdw-history-wrapper,.rdw-embedded-wrapper,.rdw-emoji-wrapper{
    display: none;
}
.rdw-list-wrapper{
    .rdw-option-wrapper:last-child,
       .rdw-option-wrapper:nth-last-child(2){
           display: none;
       }
}
}